import jQuery from 'jquery';
import {cartIsEmpty} from '../../services/cartService';
import {registerPageCb} from '../../services/pagesService';
import {trackSummaryStep} from '../ecommercefunnel';

export default function(){
	registerPageCb({
		pageLabel: 'checkout-summary-view'
	}, function(){
		trackSummaryStep();
	});
}