import {parseUrl as parseUrl} from '../utils/urlParser';

const param = "tuiel";

export default function(){
	// add a tuiel request parameter to ajax request if its not available
	jQuery.ajaxPrefilter(function (options, originalOptions, jqXHR)
	{
		const parsedUrl = parseUrl(options.url);
		if((window.location.hostname === parsedUrl.host && window.location.protocol === parsedUrl.protocol && window.location.port === parsedUrl.port)){
			const noData = (typeof options.data === "undefined");
			if (options.type === "post" || options.type === "POST")
			{
				
				if (noData || options.data.indexOf(param) === -1)
				{
					options.data = (!noData ? options.data + "&" : "") + param + "=" + kps.uiel;
				}
			}else if(options.type === "GET" && (noData || (options.data.indexOf(param) === -1))){
				if((options.url.indexOf(param) === -1)){
					if(options.url.indexOf('?')  === -1){
						options.url += '?';
					}else{
						options.url += '&';
					}
					
					options.url += param + '=' + kps.uiel;
				}
			}
		}
	});
}