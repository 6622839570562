import cookiesChecker from './cookiesChecker';
import {getLanguagePath} from '../../services/languageService';
import jQuery from 'jquery';
import jsCookie from 'js-cookie';
import jQueryUi from 'jquery-ui';
import {uniq} from 'underscore';
import URI from 'urijs';

import PerfectScrollbar from 'perfect-scrollbar';

const elmClasses = 'cookies-overlay-widget component';

window.consentChangedCbs = window.consentChangedCbs || [];


jsCookie.json = true;

//Configuration
const config = {
	cookieName: 'cookieconsentv4',
	localConfig: {
		key: 'cookieconsentcfgv4',
		expires: function () {
			return new Date(new Date().getTime() + 30 * 60 * 1000); // 30 minutes
		}
	},
	sameSite: 'None',
	secure: true,
	expires: 365
};

//Service
class TrackingService {
	constructor() {
		const service = this;

		this._initialized = false;
		this._initializing = true;

		this._readyPromise = this._initializeConfig()
			.then(function () {
				return service._initializeState();
			})
			.then(function () {
				service._initialized = true;
				service._initializing = false;

				service._notifiedState = {};
				service._triggerCallbacks();
				service._wrapCallbacksPush();
			});
	}

	_initializeConfig() {
		const service = this;

		return this._loadConfig()
			.then(function (config) {
				service._config = config;
			})
	}

	_loadConfig() {
		const service = this;
		return this._loadLocalConfig()
			.then(function (config) {
				return config;
			}, function () {
				return service._loadRemoteConfig.apply(service, [])
					.then(function (remoteConfig) {
						const convertedRemoteConfig = service._convertRemoteConfig(remoteConfig);

						const config = jQuery.extend({}, convertedRemoteConfig);
						config.version = service._generateVersion(config);

						return config;
					})
					.then(function (remoteConfig) {
						service._persistLocalConfig.apply(service, [remoteConfig]);

						return remoteConfig;
					});
			});
	}

	_loadLocalConfig() {
		const deferred = jQuery.Deferred();

		if (typeof localStorage !== 'undefined') {
			let storedConfig = null;
			const storedConfigStr = localStorage.getItem(this._getLocalKey());

			if (typeof storedConfigStr !== 'undefined' && storedConfigStr != null) {
				storedConfig = JSON.parse(storedConfigStr);
			}

			if (typeof storedConfig === 'undefined' || storedConfig == null) {
				deferred.reject({
					reason: 'Not present'
				});
			} else {
				const storedDate = new Date(storedConfig.expires);
				if (new Date() > storedDate) {
					deferred.reject({
						reason: 'Expired'
					});
				} else {
					deferred.resolve(storedConfig.config);
				}
			}
		} else {
			deferred.reject({
				reason: 'localStorage not available'
			});
		}

		return deferred.promise();
	}

	_getLocalKey() {
		const languagePath = getLanguagePath();
		return config.localConfig.key + '_' + languagePath;
	}

	_persistLocalConfig(configuration) {
		if (typeof localStorage !== 'undefined') {

			const storedConfig = {
				config: configuration,
				expires: config.localConfig.expires()
			};

			localStorage.setItem(this._getLocalKey(), JSON.stringify(storedConfig));
		}
	}

	_loadRemoteConfig() {
		return jQuery.ajax({
			cache: false,
			dataType: 'json',
			url: '/api/cookie/list'
		});
	}

	_convertRemoteConfig(remoteConfig) {
		const cookies = {};
		const groups = {};

		jQuery.each(remoteConfig, function (idx, section) {
			if (section.distributors.length !== 0) {
				let anyCookieRequired = false;
				const cookieNames = [];
				jQuery.each(section.distributors, function (distIdx, distributor) {
					cookies[distributor.code] = {
						required: distributor.required,
						name: distributor.name,
						description: distributor.description,
						urls: distributor.urls,
						modified: distributor.modified,
						dependencies: distributor.dependencies
					};

					cookieNames.push(distributor.code);
					if (distributor.required) {
						anyCookieRequired = true;
					}
				});

				groups[section.code] = {
					name: section.name,
					description: section.description,
					cookies: cookieNames,
					required: anyCookieRequired,
					order: section.order,
					modified: section.modified
				};
			}
		});

		return {
			groups: groups,
			cookies: cookies
		}
	}

	_generateVersion(config) {
		const objectToHash = this._generateObjectToHash(config);
		const objectStr = JSON.stringify(objectToHash);
		return this._hashCode(objectStr);
	}

	_generateObjectToHash(config) {
		const groups = [];
		const cookies = [];

		jQuery.each(config.groups, function (configGroupIdx, configGroup) {
			const group = jQuery.extend(true, {}, configGroup);
			delete group.name;
			delete group.description;

			groups.push(group);
		});

		jQuery.each(config.cookies, function (configCookieIdx, configCookie) {
			const cookie = jQuery.extend(true, {}, configCookie);
			delete cookie.name;
			delete cookie.description;

			jQuery.each(cookie.urls, function (urlIdx, url) {
				delete url.label;
				delete url.url;
			});

			cookies.push(cookie);
		});

		return {
			groups: groups,
			cookies: cookies
		};
	}

	_hashCode(inputStr) {
		let hash = 0;
		if (inputStr.length !== 0) {
			for (let i = 0; i < inputStr.length; i++) {
				const chr = inputStr.charCodeAt(i);
				hash = ((hash << 5) - hash) + chr;
				hash |= 0; // Convert to 32bit integer
			}
		}

		return hash;
	}

	getReadyPromise() {
		return this._readyPromise;
	}

	_initializeState() {
		this._initializing = true;

		const deferred = jQuery.Deferred();

		const oldState = this._loadOldState();

		const state = {};
		jQuery.each(this._config.cookies, function (cookieName, cookieSettings) {
			const oldCookieAcceptedValue = oldState[cookieName];
			const cookieAccepted = (typeof oldCookieAcceptedValue !== 'undefined') ? oldCookieAcceptedValue : false;

			state[cookieName] = cookieAccepted;
		});

		this._state = state;

		this._initializing = false;

		deferred.resolve({});

		return deferred.promise();
	}

	_assertReady() {
		if (!this._initialized && !this._initializing) {
			throw "Service not yet initialized";
		}
	}

	getState() {
		this._assertReady();
		return this._state;
	}

	_loadOldState() {
		this._assertReady();

		const oldState = this._getCookie();

		if (typeof oldState === 'undefined' || typeof oldState.s === 'undefined') {
			return {};
		} else {
			return oldState.s;
		}
	}

	acceptAll() {
		this._assertReady();

		const groupCodes = this.getGroupCodes();
		this.acceptGroups(groupCodes);
	}

	getGroupCodes() {
		this._assertReady();

		const groupCodes = [];
		jQuery.each(this.getGroups(), function (groupCode, groupSettings) {
			groupCodes.push(groupCode);
		});
		return groupCodes;
	}

	getGroupCookies(groupCode) {
		this._assertReady();

		const groupCookies = [];

		const cookies = this._config.cookies;
		const groups = this._config.groups;
		const isValid = this._validate(groups, groupCode);
		if (isValid) {
			const cookieCodes = groups[groupCode].cookies;
			jQuery.each(cookieCodes, function (idx, cookieCode) {
				const cookie = jQuery.extend({}, cookies[cookieCode], {
					code: cookieCode
				});
				groupCookies.push(cookie);
			});
		}

		return groupCookies;
	}

	accept(cookieName, persist) {
		const service = this;

		this._assertReady();

		if (typeof persist === 'undefined') {
			persist = true;
		}

		const isValid = this._validate(this._config.cookies, cookieName);
		if (isValid) {
			service._state[cookieName] = true;

			const dependentCookieNames = service.getAllCookieDependencies(cookieName);
			jQuery.each(dependentCookieNames, function (idx, dependentCookieName) {
				service._state[dependentCookieName] = true;
			});

			if (persist) {
				service._persist();
			}
		}

		return isValid;
	}

	acceptMany(cookieCodes, persist) {
		this._assertReady();

		if (typeof persist === 'undefined') {
			persist = true;
		}

		const service = this;

		jQuery.each(cookieCodes, function (idx, cookieCode) {
			service.accept(cookieCode, false);
		});

		if (persist) {
			service._persist();
		}
	}

	reset(cookieName, persist) {
		const service = this;

		this._assertReady();

		if (typeof persist === 'undefined') {
			persist = true;
		}

		const isValid = this._validate(this._config.cookies, cookieName);
		if (isValid) {
			service._state[cookieName] = false;

			const dependentCookieNames = service.getAllCookieDependencies(cookieName);
			jQuery.each(dependentCookieNames, function (idx, dependentCookieName) {
				service._state[dependentCookieName] = false;
			});

			if (persist) {
				service._persist();
			}
		}

		return isValid;
	}

	resetMany(cookieCodes, persist) {
		this._assertReady();

		if (typeof persist === 'undefined') {
			persist = true;
		}

		const service = this;

		jQuery.each(cookieCodes, function (idx, cookieCode) {
			service.reset(cookieCode, false);
		});

		if (persist) {
			service._persist();
		}
	}

	acceptGroup(groupCode, persist) {
		this._assertReady();

		const service = this;

		if (typeof persist === 'undefined') {
			persist = true;
		}

		const config = this._config;
		const groups = config.groups;

		const isValid = this._validate(groups, groupCode);
		if (isValid) {
			const cookieNames = groups[groupCode].cookies;
			jQuery.each(cookieNames, function (index, cookieName) {
				if (!service.accept.apply(service, [cookieName])) {
					isValid = false;
				}
			});
		}

		if (persist) {
			this._persist();
		}

		return isValid;
	}

	acceptGroups(groupCodes, persist) {
		this._assertReady();

		if (typeof persist === 'undefined') {
			persist = true;
		}

		const service = this;

		jQuery.each(groupCodes, function (idx, groupCode) {
			service.acceptGroup(groupCode, false);
		});

		if (persist) {
			service._persist();
		}
	}

	isCookieAccepted(cookieName) {
		this._assertReady();

		return this._state[cookieName] === true;
	}

	isGroupAccepted(groupCode) {
		this._assertReady();

		const service = this;

		const groups = this.getGroups();
		const isValid = this._validate(groups, groupCode);

		let groupAccepted = isValid;
		if (isValid) {
			const group = groups[groupCode];
			jQuery.each(group.cookies, function (cookieName, cookieSettings) {
				if (!service.isCookieAccepted(cookieName)) {
					groupAccepted = false;
				}
			})
		}

		return groupAccepted;
	}

	_triggerCallback(cb, force) {
		this._assertReady();

		if (typeof force === 'undefined') {
			force = false;
		}

		const service = this;
		const state = service._state;
		const notifiedState = service._notifiedState;

		jQuery.each(state, function (cookieName, consentAccepted) {
			if (force || notifiedState[cookieName] !== consentAccepted) {
				cb(cookieName, consentAccepted);
			}
		});
	}

	_triggerCallbacks() {
		this._assertReady();

		const service = this;
		const state = service._state;

		jQuery.each(window.consentChangedCbs, function (index, cb) {
			service._triggerCallback.apply(service, [cb]);
		});

		//Clone state for the next diff
		this._notifiedState = jQuery.extend({}, state);
	}

	_wrapCallbacksPush() {
		this._assertReady();

		const service = this;

		const pushBefore = window.consentChangedCbs.push;
		window.consentChangedCbs.push = function (cb) {
			pushBefore.apply(window.consentChangedCbs, [cb]);

			service._triggerCallback.apply(service, [cb, true]);
		}
	}

	_validate(object, key) {
		return typeof object[key] !== 'undefined';
	}

	isCurrentVersionPersisted() {
		this._assertReady();

		const persistedCookie = this._getCookie();
		return typeof persistedCookie !== 'undefined' && persistedCookie['v'] === this._config.version;
	}

	_getCookie() {
		this._assertReady();

		return jsCookie.get(config.cookieName, {
			json: true,
			secure: config.secure
		});
	}

	_persist() {
		this._assertReady();

		jsCookie.set(config.cookieName, {
			v: this._config.version,
			s: this._state
		}, {
			json: true,
			sameSite: config.sameSite,
			secure: config.secure,
			expires: config.expires
		});

		this._triggerCallbacks();
	}

	getGroups() {
		this._assertReady();

		return this._config.groups;
	}

	getSortedGroups() {
		const groups = this.getGroups();
		const sortedGroups = [];

		jQuery.each(groups, function (groupCode, group) {
			const newGroupFormat = jQuery.extend({}, group, {
				code: groupCode
			});

			sortedGroups.push(newGroupFormat);
		});

		sortedGroups.sort(function (group1, group2) {
			return group1.order - group2.order;
		});

		return sortedGroups;
	}

	getCookies() {
		this._assertReady();

		return this._config.cookies;
	}

	getAllCookieDependencies(cookieCode) {
		const cookiesCodes = [];

		const codesToCheck = [cookieCode];
		const checkedCodes = {};

		const cookies = this.getCookies();
		while (codesToCheck.length !== 0) {
			const codeToCheck = codesToCheck[0];
			codesToCheck.splice(0, 1);

			if (checkedCodes[codeToCheck] !== true) {
				const cookie = cookies[codeToCheck];

				jQuery.each(cookie.dependencies, function (cookieIdx, depCookieCode) {
					codesToCheck.push(depCookieCode);
					cookiesCodes.push(depCookieCode);
				});

				checkedCodes[codeToCheck] = true;
			}
		}

		return uniq(cookiesCodes)
			.filter(function (currCookieCode) {
				return cookieCode !== currCookieCode;
			});
	}

	getAllDependentCookies(cookieCode) {
		const dependentCookies = [];

		const codesToCheck = [cookieCode];
		const checkedCodes = {};

		const cookies = this.getCookies();
		while (codesToCheck.length !== 0) {
			const codeToCheck = codesToCheck[0];
			codesToCheck.splice(0, 1);

			if (checkedCodes[codeToCheck] !== true) {
				jQuery.each(cookies, function (cookieCode, cookie) {
					if (cookie.dependencies.includes(codeToCheck)) {
						codesToCheck.push(cookieCode);
						dependentCookies.push(cookieCode);
					}
				});

				checkedCodes[codeToCheck] = true;
			}
		}

		return uniq(dependentCookies)
			.filter(function (currCookieCode) {
				return cookieCode !== currCookieCode;
			});
	}

	resetCookies() {

	}
}

const trackingServiceInstance = new TrackingService(config);

//Widget
jQuery.widget('ep.cookiesoverlay', {
	options: {
		classes: {
			bodyOpen: 'cookielayer-open'
		},
		texts: {
			title: __('cookiesoverlay.title'),
			description: __('cookiesoverlay.description'),
			dependencies: __('cookiesoverlay.dependencies'),
			buttons: {
				acceptAll: __('cookiesoverlay.buttons.acceptall'),
				save: __('cookiesoverlay.buttons.save'),
				fewer: __('cookiesoverlay.buttons.details.fewer'),
				more: __('cookiesoverlay.buttons.details.more')
			},
			overviewLink: __('cookiesoverlay.link.to.overview')
		},
		links: [
			{
				name: __('overlays.links.privacy.label'),
				href: __('overlays.links.privacy.href')
			},
			{
				name: __('overlays.links.impress.label'),
				href: __('overlays.links.impress.href')
			}
		],
		trackingService: trackingServiceInstance
	},
	_create: function () {
		const widget = this;
		const options = widget.options;
		const trackingService = options.trackingService;

		const texts = options.texts;

		widget.displaysInitialState = true;

		trackingService.getReadyPromise()
			.then(function () {
				const body = jQuery('body');
				if (!trackingService.isCurrentVersionPersisted() && !body.hasClass('in-layer')) {
					const bodyOpenClass = options.classes.bodyOpen;
					body.addClass(bodyOpenClass);

					const createElm = function (classes, children, type) {
						if (typeof type === 'undefined') {
							type = 'div';
						}

						const elm = jQuery('<' + type + '/>');
						elm.addClass(classes);

						if (typeof children !== undefined) {
							elm.append(children);
						}

						return elm;
					}

					const createRandomId = function () {
						return 'i' + Math.random().toString(36).substr(2, 9);
					}

					const element = widget.element;
					element.addClass(elmClasses);

					const underlayElm = createElm('cookies-overlay-underlay');

					const dialogTitleElm = createElm('cookies-overlay-dialog__header-title');
					dialogTitleElm.text(texts.title);

					const dialogHeaderElm = createElm('cookies-overlay-dialog__header', [dialogTitleElm]);

					const dialogBodyDescriptionElm = createElm('cookies-overlay-dialog__body-description content');
					dialogBodyDescriptionElm.append(texts.description);

					const pageTwoReturnLinkElm = createElm('cookies-overlay-dialog__page-two-header-link', [], 'a');
					pageTwoReturnLinkElm.text(texts.overviewLink);
					pageTwoReturnLinkElm.attr('href', '#');

					const pageTwoHeaderContainerElm = createElm('cookies-overlay-dialog__page-two-header', [pageTwoReturnLinkElm], 'div');

					const checkboxesElms = [];
					const pageTwoContainerChildren = []

					const sortedGroups = trackingService.getSortedGroups();

					const inputsByGroup = {};
					const inputForCookieCode = {};

					jQuery.each(sortedGroups, function (indx, groupConfig) {
						const id = createRandomId();

						const groupCode = groupConfig.code;

						const inputElm = createElm('checkbox-item', [], 'input');
						inputElm.attr('id', id);
						inputElm.attr('type', 'checkbox');
						inputElm.attr('data-groupcode', groupCode);

						const inputHiddenElm = createElm('checkbox-item-hidden', [], 'input');
						inputHiddenElm.attr('type', 'hidden');

						const checkboxLabelElm = createElm('control-label checkbox', [], 'label');
						const groupConfigName = createElm('cookies-overlay-dialog__checkbox-name', [], 'span');
						const groupConfigDescription = createElm('cookies-overlay-dialog__checkbox-description', [], 'span');

						groupConfigName.html(groupConfig.name);
						groupConfigName.append('<br/>');
						groupConfigDescription.html(groupConfig.description);

						const pageTwoGroupTitleElm = createElm('cookie-group-section__title', [], 'span');
						const pageTwoGroupDescElm = createElm('cookie-group-section__description', [], 'span');
						const pageTwoGroupCoookieContainerElm = createElm('cookie-group-section__cookie-container', [], 'div');

						pageTwoGroupTitleElm.html(groupConfig.name);
						pageTwoGroupDescElm.html(groupConfig.description);

						checkboxLabelElm.append(groupConfigName);
						checkboxLabelElm.append(groupConfigDescription);

						checkboxLabelElm.attr('for', id);

						const controlsElm = createElm('controls checkbox', [inputElm, inputHiddenElm, checkboxLabelElm]);

						const checkboxCgElm = createElm('control-group', [controlsElm]);

						const sectionElmChilds = [checkboxCgElm];
						const pageTwoCookieGroupChildren = [pageTwoGroupTitleElm, pageTwoGroupDescElm, pageTwoGroupCoookieContainerElm];

						const groupCookies = trackingService.getGroupCookies(groupCode);

						if (groupCookies.length !== 0) {
							const currentUri = new URI();
							const currentHostname = currentUri.hostname();

							const sectionCookiesMoreDetailsLinkElm = createElm('cookies-overlay-dialog__section-cookies-link', [], 'a');
							sectionCookiesMoreDetailsLinkElm.text(texts.buttons.more);
							sectionCookiesMoreDetailsLinkElm.attr('href', '#cookie-overlay');
							sectionCookiesMoreDetailsLinkElm.attr('data-groupcode', groupCode);

							const sectionCookiesElm = createElm('cookies-overlay-dialog__section-cookies', [sectionCookiesMoreDetailsLinkElm]);
							sectionElmChilds.push(sectionCookiesElm);

							jQuery.each(groupCookies, function (idx, groupCookie) {
								const cookieInputId = "cookies-overlay-dialog__cookie-input-" + groupCookie.code;

								const cookieInputElm = createElm('cookies-overlay-dialog__section-cookie-checkbox checkbox-item', [], 'input');
								cookieInputElm.attr('id', cookieInputId);
								cookieInputElm.attr('type', 'checkbox');
								cookieInputElm.attr('data-cookiecode', groupCookie.code);

								inputForCookieCode[groupCookie.code] = cookieInputElm;

								const sectionCookieNameElm = createElm('cookies-overlay-dialog__section-cookiename control-label checkbox', [], 'label');
								sectionCookieNameElm.text(groupCookie.name);
								sectionCookieNameElm.attr('for', cookieInputId);

								const cookieInputHiddenElm = createElm('cookies-overlay-dialog__section-cookie-hidden', [], 'input');
								cookieInputHiddenElm.attr('type', 'hidden');

								const sectionCookieControls = createElm('cookies-overlay-dialog__section-cookieinput controls checkbox', [cookieInputElm, cookieInputHiddenElm, sectionCookieNameElm]);

								if (groupCookie.required === true) {
									cookieInputElm.prop('checked', true);
									cookieInputElm.prop('disabled', true);
									sectionCookieControls.addClass('disabled');
								} else {
									cookieInputElm.prop('checked', trackingService.isCookieAccepted(groupCookie.code));
								}

								const sectionCookieInputElm = createElm('cookies-overlay-dialog__section-cookieinput control-group ', [sectionCookieControls]);

								const sectionCookieDescrElm = createElm('cookies-overlay-dialog__section-cookiedesc');
								sectionCookieDescrElm.text(groupCookie.description);

								const sectionCookieDepsLabelElm = createElm('cookies-overlay-dialog__section-cookiedeps-label', []);
								sectionCookieDepsLabelElm.text(texts.dependencies + ':');

								const allCookieDeps = trackingService.getAllCookieDependencies(groupCookie.code);

								let sectionCookieDepsElm = undefined;
								if (allCookieDeps.length !== 0) {
									const depCookieNames = [];

									const cookies = trackingService.getCookies();
									jQuery.each(allCookieDeps, function (depCookieIdx, depCookieCode) {
										const cookie = cookies[depCookieCode];
										if (typeof cookie !== 'undefined') {
											depCookieNames.push(cookie.name);
										}
									});

									if (depCookieNames.length !== 0) {
										const depsStr = depCookieNames.join(', ');

										const sectionCookieDepsValueElm = createElm('cookies-overlay-dialog__section-cookiedeps-value', []);
										sectionCookieDepsValueElm.text(depsStr);

										sectionCookieDepsElm = createElm('cookies-overlay-dialog__section-cookiedeps', [sectionCookieDepsLabelElm, sectionCookieDepsValueElm]);
									}
								}

								const urls = [];
								jQuery.each(groupCookie.urls, function (urlIdx, url) {
									let uri = new URI(url.url);

									let hostname = uri.hostname();
									if (hostname == '') {
										uri = uri.absoluteTo(window.location.href);
									}

									hostname = uri.hostname();
									const internalUrl = hostname === currentHostname;

									const urlElm = createElm('cookies-overlay-dialog__section-cookieurl', [], 'a');
									if (internalUrl) {
										urlElm.addClass('in-layer-link');
									} else {
										urlElm.attr('target', '_blank');
									}
									urlElm.attr('href', url.url);
									urlElm.text(url.label);

									urls.push(urlElm);
								});

								const sectionCookieElmChilds = [sectionCookieInputElm, sectionCookieDescrElm];
								if (typeof sectionCookieDepsElm !== 'undefined') {
									sectionCookieElmChilds.push(sectionCookieDepsElm);
								}

								if (urls.length !== 0) {
									const sectionCookieUrlsElm = createElm('cookies-overlay-dialog__section-cookieurls', urls);
									sectionCookieElmChilds.push(sectionCookieUrlsElm);
								}

								const sectionCookieElm = createElm('cookies-overlay-dialog__section-cookie', sectionCookieElmChilds);

								pageTwoGroupCoookieContainerElm.append(sectionCookieElm);

							});
						}

						const sectionElm = createElm('cookies-overlay-dialog__section', sectionElmChilds);
						checkboxesElms.push(sectionElm);

						const pageTwoCookieGroupContainerElm = createElm('cookies-overlay-dialog__hidden cookie-group-section__group-' + groupCode, pageTwoCookieGroupChildren, 'div');
						pageTwoContainerChildren.push(pageTwoCookieGroupContainerElm);

						if (groupConfig.required === true) {
							inputElm.prop('checked', true);
							inputElm.prop('disabled', true);
							controlsElm.addClass('disabled');
						} else {
							inputElm.prop('checked', trackingService.isGroupAccepted(groupCode));
						}

						inputsByGroup[groupCode] = inputElm;
					});

					const dialogBodyCheckboxesElm = createElm('cookies-overlay-dialog__body-checkboxes', checkboxesElms);

					const pageTwoBodyContainerElm = createElm('cookies-overlay-dialog__page-two-content', pageTwoContainerChildren, 'div');

					const dialogBodyPageOneContentElm = createElm('cookies-overlay-dialog__page-one-container', [dialogBodyDescriptionElm, dialogBodyCheckboxesElm]);

					const dialogBodyPageTwoContentElm = createElm('cookies-overlay-dialog__page-two-container', [pageTwoHeaderContainerElm, pageTwoBodyContainerElm]);

					const dialogBodySliderElm = createElm('cookies-overlay-dialog__body-slider', [dialogBodyPageOneContentElm, dialogBodyPageTwoContentElm]);
					dialogBodySliderElm.attr('id', 'cookie-overlay');

					const dialogBodyElm = createElm('cookies-overlay-dialog__body', [dialogBodySliderElm]);

					const acceptAllButtonElm = createElm('cookies-overlay-dialog__accept-all-btn', [], 'button');
					acceptAllButtonElm.text(texts.buttons.acceptAll);

					const saveButtonElm = createElm('cookies-overlay-dialog__save-btn', [], 'button');
					saveButtonElm.text(texts.buttons.save);

					const dialogFooterButtonsElm = createElm('cookies-overlay-dialog__footer-buttons', [acceptAllButtonElm, saveButtonElm]);

					const linksElms = [];
					jQuery.each(options.links, function (linkIdx, linkSettings) {
						const linkElm = createElm('cookie-overlay-dialog__footer-link in-layer-link', [], 'a');
						linkElm.attr('href', linkSettings.href);
						linkElm.text(linkSettings.name);

						linksElms.push(linkElm);
					});

					const dialogFooterLinksElm = createElm('cookies-overlay-dialog__footer-links', linksElms);

					const dialogFooterElm = createElm('cookies-overlay-dialog__footer', [dialogFooterButtonsElm, dialogFooterLinksElm]);

					const dialogElm = createElm('cookies-overlay-dialog', [dialogHeaderElm, dialogBodyElm, dialogFooterElm]);
					element.append([underlayElm, dialogElm]);

					const groupInputs = dialogBodyElm.find('.checkbox-item[data-groupcode]');

					const checkOrUncheckCookies = function (shouldCheck, cookieCodes) {
						jQuery.each(cookieCodes, function (idx, cookieCode) {
							inputForCookieCode[cookieCode].prop('checked', shouldCheck);
						});
					}

					const markCookie = function (cookieCode) {
						const cookieDependencies = trackingService.getAllCookieDependencies(cookieCode);

						const cookiesCodes = cookieDependencies.slice();
						cookiesCodes.push(cookieCode);

						checkOrUncheckCookies(true, cookiesCodes);
					}

					const unmarkCookie = function (cookieCode) {
						const allDependentCookies = trackingService.getAllDependentCookies(cookieCode);

						const cookiesCodes = allDependentCookies.slice();
						cookiesCodes.push(cookieCode);

						checkOrUncheckCookies(false, cookiesCodes);
					}

					const updateGroupCheckboxes = function () {
						groupInputs.each(function () {
							const groupInput = jQuery(this);
							const groupCode = groupInput.attr('data-groupcode');
							const bodySlider = widget.element.find('.cookies-overlay-dialog__body-slider');
							const groupContainer = bodySlider.find('.cookie-group-section__group-' + groupCode);

							const cookieInputs = groupContainer.find('.checkbox-item[data-cookiecode]');
							const selectedCookieInputs = cookieInputs.filter(':checked');

							var isChecked = false;
							var isIndeterminate = false;
							if (cookieInputs.length === selectedCookieInputs.length) {
								isChecked = true;
								isIndeterminate = false;
							} else if (selectedCookieInputs.length === 0) {
								isChecked = false;
								isIndeterminate = false;
							} else {
								isChecked = false;
								isIndeterminate = true;
							}

							groupInput.prop('checked', isChecked);
							groupInput.prop("indeterminate", isIndeterminate);
						});
					}

					widget._on(groupInputs, {
						'change': function (event) {
							const widget = this;
							const element = this.element;

							const groupInput = jQuery(event.target);
							const isChecked = groupInput.prop('checked');

							const groupCode = groupInput.attr('data-groupcode');

							const bodySlider = element.find('.cookies-overlay-dialog__body-slider');

							const groupContainer = bodySlider.find('.cookie-group-section__group-' + groupCode);

							const cookieInputs = groupContainer.find('.checkbox-item[data-cookiecode]');

							cookieInputs.each(function () {
								const cookieInput = jQuery(this);
								const cookieCode = cookieInput.attr('data-cookiecode');

								if (isChecked) {
									markCookie(cookieCode);
								} else {
									unmarkCookie(cookieCode);
								}
							});

							updateGroupCheckboxes();
						}
					});

					const cookieInputs = dialogBodyElm.find('.checkbox-item[data-cookiecode]');
					widget._on(cookieInputs, {
						'change': function (event) {
							const target = jQuery(event.target);
							const cookieCode = target.attr('data-cookiecode');
							if (target.prop('checked')) {
								markCookie(cookieCode);
							} else {
								unmarkCookie(cookieCode);
							}

							updateGroupCheckboxes();
						}
					});

					const moreDetailsBtns = dialogBodyElm.find('.cookies-overlay-dialog__section-cookies-moredetails-link');
					widget._on(moreDetailsBtns, {
						'click': function (event) {
							event.preventDefault();

							widget._ps.update();
						}
					});

					widget._on(acceptAllButtonElm, {
						'click': function () {
							trackingService.acceptAll();
							widget.destroy();
						}
					});

					widget._on(saveButtonElm, {
						'click': function () {
							const cookieCodes = [];
							const cookieSections = widget.element.find('.cookies-overlay-dialog__section-cookie');
							jQuery.each(cookieSections, function () {
								const cookieSection = $(this);
								const checkbox = cookieSection.find('.checkbox-item');
								const isChecked = checkbox.is(':checked');
								if (isChecked) {
									const cookieCode = checkbox.data('cookiecode');
									cookieCodes.push(cookieCode);
								}
							});
							trackingService.acceptMany(cookieCodes);
							widget.destroy();
						}
					});

					widget._ps = new PerfectScrollbar(dialogBodyElm[0], {
						handlers: ['click-rail', 'keyboard', 'wheel', 'touch'], // Removed 'drag-thumb'
						suppressScrollX: true
					});

					updateGroupCheckboxes();

					widget._onResizeListener = function () {
						widget._ps.update();
					};

					jQuery(window).on('resize', widget._onResizeListener);

					widget._setUpAnimationListeners();
				} else {
					jQuery('body').trigger('consentready');
				}
			});
	},
	_setUpAnimationListeners: function () {
		const widget = this;
		const element = this.element;

		var sectionCookiesMoreDetailsLinkElm = element.find('.cookies-overlay-dialog__section-cookies-link');
		sectionCookiesMoreDetailsLinkElm.on('click', widget._triggerAnimation.bind(this));

		var pageTwoHeaderLinkElm = element.find('.cookies-overlay-dialog__page-two-header-link');
		pageTwoHeaderLinkElm.on('click', widget._triggerAnimation.bind(this));

		var slider = element.find('.cookies-overlay-dialog__body-slider');
		slider.on('animationend webkitAnimationEnd oAnimationEnd', widget._handleAnimationEndEvent.bind(this));
	},
	_triggerAnimation: function (event) {
		const widget = this;
		const slider = widget.element.find('.cookies-overlay-dialog__body-slider');
		if (widget.displaysInitialState) {
			slider.addClass('cookies-overlay-dialog__body-slider--animation-normal');
		} else {
			slider.addClass('cookies-overlay-dialog__body-slider--animation-reverse');
		}

		const clickedLink = $(event.target);
		const groupCode = clickedLink.attr('data-groupcode');

		if (groupCode != undefined) {
			const allCookiegroups = slider.find("div[class*='cookie-group-section__group-']");
			const targetedCookieGroup = slider.find('.cookie-group-section__group-' + groupCode);
			allCookiegroups.hide();
			targetedCookieGroup.show();
		}
		slider.addClass('cookies-overlay-dialog__body-slider--animation');

		if (widget.displaysInitialState) {
			slider.addClass('cookies-overlay-dialog__body-slider--animation-page-two');

		} else {
			slider.removeClass('cookies-overlay-dialog__body-slider--animation-page-two');
		}
		widget.displaysInitialState = !widget.displaysInitialState;
	},
	_handleAnimationEndEvent: function () {
		const widget = this;
		const slider = widget.element.find('.cookies-overlay-dialog__body-slider');

		slider.removeClass('cookies-overlay-dialog__body-slider--animation');
		slider.removeClass('cookies-overlay-dialog__body-slider--animation-normal');
		slider.removeClass('cookies-overlay-dialog__body-slider--animation-reverse');

		widget._ps.update();
	},
	_destroy: function () {
		const element = this.element;

		if (typeof this._onResizeListener !== 'undefined') {
			jQuery(window).off('resize', this._onResizeListener);
			this._onResizeListener = undefined;
		}

		if (typeof this._ps !== 'undefined') {
			this._ps.destroy();
			this._ps = undefined;
		}

		element.children().remove();

		element.removeClass(elmClasses);

		jQuery('body').removeClass(this.options.classes.bodyOpen);
		jQuery('body').trigger('consentready');
	}
});

let initialized = false;
const defaultFunc = function () {
	if (!initialized) {
		initialized = true;

		jQuery(function () {
			const wrapper = jQuery('#main-body-wrapper');
			if (wrapper.length !== 0 && cookiesChecker()) {
				const consentElm = jQuery('<div/>');
				wrapper.prepend(consentElm);
				consentElm.cookiesoverlay();
			}
			;
		});
	}
}


export {defaultFunc as default, trackingServiceInstance as trackingService}
