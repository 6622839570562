import "core-js/stable";
import "regenerator-runtime/runtime";
import config from './config/_config';
import tracking from "./tracking/_tracking";
import isTouchScreen from "./utils/epTouchEvents";
require("./passiveevents/index.js");


config();
tracking();
isTouchScreen();

import('./cmsasync').then(function(cmsasync){
	cmsasync['default']();
});
