import jQuery from 'jquery';
import {trackingService} from './services/tracking';

window.consentChangedCbs = window.consentChangedCbs || [];

const timeoutTime = 200;

let dataLayerQueue = [];

let initialized = false;
function defaultFunc(){
	if(!initialized){
		initialized = true;
		
		dataLayerQueue.push({
			event: 'cookies-accepted'
		});
		
		jQuery(function(){
			const bodyElm = jQuery('body');

			let trackingEvent = {
				event: 'pageview',
				inLayer: bodyElm.hasClass('in-layer')
			};

			extendTrackingEvent(bodyElm, trackingEvent);

			dataLayerQueue.push(trackingEvent);
		});
	}
}

var etrackerActivated = false;
window.consentChangedCbs.push(function(cookieName, consentAccepted){
	if(!etrackerActivated && cookieName == 'etracker' && consentAccepted){
		jQuery(function(){
			const etTracklet = jQuery('#et_tracklet');
			if(etTracklet.length !== 0){
				const secureCode= etTracklet.attr('data-secure-code');
				const src = etTracklet.attr('data-et-src');
				
				const scriptTag = jQuery('<script id="_etLoader" type="text/javascript" charset="UTF-8" data-secure-code="'+ secureCode + '" src="' + src + '" async/>');
				scriptTag.insertAfter(etTracklet);
			}
		});
		etrackerActivated = true;
	}
});


function isGoogleTagManagerEnabled(){
	return typeof window.dataLayer !== 'undefined';
}

function enrichEvent(event){
	const state = trackingService.getState();
	event.consents = state;
}

function enrichEvents(events){
	jQuery.each(events, function(idx, event){
		enrichEvent(event);
	});
}

if(isGoogleTagManagerEnabled()){
	jQuery(function(){
		var gtmActivated = false;
		jQuery('body').on('consentready', function(){
			if(!gtmActivated){
				enrichEvents(dataLayerQueue);
				
				window.consentChangedCbs.push(function(cookieName, consentAccepted){
					const consentEvent = {
						'accepted': consentAccepted,
						'cookieName': cookieName,
						'event': 'cookieConsentChanged'
					};
					
					enrichEvent(consentEvent);
					
					if(gtmActivated){
						dataLayerQueue.push(consentEvent);
					}else{
						dataLayerQueue.splice(0, 0, consentEvent);
					}
				});
				
				window.dataLayer.push(...dataLayerQueue);
				dataLayerQueue = window.dataLayer;
				
				gtmActivated = true;
			}
		});
	});
}

function queueDataLayerEvents(eventOrEvents, timeOutEnabled){
	return trackingService.getReadyPromise().then(function(){
		if(typeof timeOutEnabled === 'undefined'){
			timeOutEnabled = false;
		}
		
		if(jQuery.isArray(eventOrEvents)){
			const promisesList = [];
			jQuery.each(eventOrEvents, function(index, event){
				const promise = queueDataLayerEvents(event, timeOutEnabled);
				promisesList.push(promise);
			});
			
			return jQuery.when(promisesList);
		}else{
			const deferred = jQuery.Deferred();
			
			let cbCalled = false;
			
			let timer = undefined;
			const oldCb = eventOrEvents.eventCallback;
			const newEventCallback = function(){
				if(typeof timer !== 'undefined'){
					clearTimeout(timer);
				}
				
				if(!cbCalled){
					if(typeof oldCb !== 'undefined'){
						oldCb();
					}
					deferred.resolve();
					
					cbCalled = true;
				}
			}
			
			enrichEvent(eventOrEvents);
			if(timeOutEnabled){
				timer = setTimeout(newEventCallback, timeoutTime);
			}
			
			eventOrEvents.eventCallback = newEventCallback
			dataLayerQueue.push(eventOrEvents);
			
			return deferred.promise();
		}
	});
}

function extendTrackingEvent(bodyElm, trackingEvent){
	let dataContainer = null;
	if (bodyElm.hasClass('pageType-CategoryPage')) {

		dataContainer = {
			pageType : 'category',
			shopId : bodyElm.data('category-path'),
			trackingProducts : kps.trackingProducts
		};
	}
	else if (bodyElm.hasClass('pageLabel-homepage')) {
		dataContainer = {
			pageType : 'homepage'
		};
	}
	else if (bodyElm.hasClass('pageLabel-search')) {

		dataContainer = {
			pageType : 'search',
			keywords : kps.userSearchKeywords,
			trackingProducts : kps.trackingProducts
		};
	}

	if (dataContainer !== null) {
		jQuery.extend(trackingEvent, dataContainer);
	}
}

export {defaultFunc as default, isGoogleTagManagerEnabled as isGoogleTagManagerEnabled, queueDataLayerEvents as queueDataLayerEvents};
