import addressSelectPageTracking from './addressSelectPageTracking';
import cartPageTracking from './cartPageTracking';
import orderConfirmationPageTracking from './orderConfirmationPageTracking';
import paymentPageTracking from './paymentPageTracking';
import summaryPageTracking from './summaryPageTracking';
import expressReservationSummaryPageTracking from './expressReservationSummaryPageTracking';
import {isGoogleTagManagerEnabled} from '../tracking';

let initialized = false;
export default function(){
	if(!initialized){
		initialized = true;
		
		if(isGoogleTagManagerEnabled()){
			addressSelectPageTracking();
			cartPageTracking();
			orderConfirmationPageTracking();
			paymentPageTracking();
			summaryPageTracking();
			expressReservationSummaryPageTracking();
		}
	}
}