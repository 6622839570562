import jQuery from 'jquery';
let preparingOverlay = false;

export default function(){
	const areCookiesEnabled = (navigator.cookieEnabled === true);
	if(!areCookiesEnabled && !preparingOverlay){
		preparingOverlay = true;
		jQuery(function(){
			if(typeof window.kps.nocookies !== 'undefined'){
				const wrapper = jQuery('#main-body-wrapper');
				if(wrapper.length !== 0){
					wrapper.prepend(jQuery(window.kps.nocookies.widget));
				}
			}
		});
	}
	
	return areCookiesEnabled;
}