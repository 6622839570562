import jQuery from 'jquery';

export default function(){
	// add a CSRF request token to POST ajax request if its not available
	jQuery.ajaxPrefilter(function (options, originalOptions, jqXHR)
	{
		// Modify options, control originalOptions, store jqXHR, etc
		if (options.type === "post" || options.type === "POST")
		{
			var noData = (typeof options.data === "undefined");
			if (noData || options.data.indexOf("CSRFToken") === -1)
			{
				options.data = (!noData ? options.data + "&" : "") + "CSRFToken=" + kps.CSRFToken;
			}
		}
	});
}