import jQuery from 'jquery';
import {cartIsEmpty} from '../../services/cartService';
import {registerPageCb} from '../../services/pagesService';
import {trackSummaryStep, trackAddressRegisterLoginGastStep, trackAddressDeliveryTypeStepNumberStep} from '../ecommercefunnel';

export default function(){
	registerPageCb({
		pageLabel: 'expressReservationSummary'
	}, function(){
		const getCustomerType = function(){
			let customerType = 'Guest';
			
			const customerTypeData = jQuery('.expressreservation-form-component').data('customer-type');
			if(customerTypeData == 'ANONYMOUS' || customerTypeData == 'GUEST'){
				//Nothing to do
			}else{
				customerType = 'User';
			}
			
			return customerType;
		}
		
		const customerType = getCustomerType();
		
		trackAddressRegisterLoginGastStep(customerType, true);
		trackAddressDeliveryTypeStepNumberStep('Reserve', true);
		
		trackSummaryStep(true);
	});
}