import jQuery from 'jquery';
import jQueryUi from 'jquery-ui';
import {loadExtenalScripts} from "../services/scriptsService";

export default function () {
	jQuery(function () {
		jQuery.widget('ep.checkoutPaymentComponent', {
			options: {
				change: null,
				payone: {
					classes: {
						jsReady: 'payone-js-ready'
					},
					scriptUrl: 'https://secure.pay1.de/client-api/js/v1/payone_hosted_min.js',
					config: {
						fields: {
							cardpan: {
								selector: 'cardpan', // put name of your div-container here
								type: 'tel', // text (default), password, tel
								size: '16',
								maxlength: '16',
								iframe: {}
							},
							cardcvc2: {
								selector: 'cardcvc2', // put name of your div-container here
								type: 'tel', // select(default), text, password, tel
								size: '4',
								maxlength: '4',
								iframe: {}
							},
							cardexpiremonth: {
								selector: 'cardexpiremonth', // put name of your div-container here
								type: 'select', // select(default), text, password, tel
								size: '2',
								maxlength: '2',
								iframe: {}
							},
							cardexpireyear: {
								selector: 'cardexpireyear', // put name of your div-container here
								type: 'select', // select(default), text, password, tel
								size: '2',
								maxlength: '2',
								iframe: {}
							}
						},
						defaultStyle: {
							input: 'border: 1px solid #333; width: 175px;',
							select: 'border: 1px solid #333;',
							iframe: {
								height: '37px',
								width: '100%'
							}
						},
						error: 'errorOutput', // area to display error-messages (optional)
						showError: true,
					},
					language: {
						de: {
							invalidCardpan: "Ungültige Kartennummer. Bitte überprüfen Sie Ihre eingegebenen Daten.", //878
							invalidCvc: "Ungültige Kartenprüfnummer. Bitte überprüfen Sie Ihre eingegebenen Daten.", //879
							invalidPanForCardtype: "Kartentyp stimmt nicht mit der Kartennummer überein. Bitte überprüfen Sie Ihre eingegebenen Daten.", //880
							invalidCardtype: "Ungültiger Kartentyp. Bitte überprüfen Sie Ihre eingegebenen Daten.", //31
							invalidExpireDate: "Ablaufdatum ungültig. Bitte überprüfen Sie Ihre eingegebenen Daten.", //1077
							invalidIssueNumber: "Ungültige Kartenfolgenummer (Issue-Number). Bitte überprüfen Sie Ihre eingegebenen Daten." //1075
						}
					},
					request: {
						request: 'creditcardcheck',
						responsetype: 'JSON',
						mode: 'test',
						mid: '10001',
						aid: '10002',
						portalid: '2000002',
						encoding: 'UTF-8',
						storecarddata: 'yes',
						hash: '5c4014cebeb361d9e186fd42c810b9b1'
					}
				}
			},
			_create: function () {
				const element = this.element;
				this._inputs = element.find('input[type=radio]');

				this._ingenicoFormformSubmited = false;

				this._submitBtn = element.find('.submit-btn');

				const setUpDeferreds = [];

				const paymentProviders = this.getPaymentProviders();
				if (paymentProviders.ingenico) {
					setUpDeferreds.push(this._setUpIngenicoSubcomponent());
				}

				if (paymentProviders.payone) {
					setUpDeferreds.push(this._setUpPayoneSubcomponent());
				}

				if (paymentProviders.easycredit) {
					setUpDeferreds.push(this._setUpEasyCreditSubcomponent());
				}

				if (paymentProviders.length === 0) {
					const deferred = jQuery.Deferred();
					deferred.reject({});

					setUpDeferreds.push(deferred);
				}

				const widget = this;
				jQuery.when.apply(this, setUpDeferreds)
					.then(function () {
						widget._on(widget._inputs, {
							'change': function (event) {
								const inputElm = jQuery(event.currentTarget);
								const value = inputElm.attr('value');

								const data = {value: value};
								widget._onChange(event, data);
								widget._trigger('change', event, data);
							}
						});

						const paymentmode = widget.paymentmode();
						if (paymentmode !== null) {
							const data = {value: paymentmode};
							widget._trigger('change', null, data);

							widget._onChange(null, data);
						}
					});
			},
			_setUpIngenicoSubcomponent: function () {
				const deferred = jQuery.Deferred();

				const widget = this;
				const element = jQuery(widget.element);

				const ingenicoSubcomponent = element.find('.ingenico-subcomponent');
				this._ingenicoSubcomponent = ingenicoSubcomponent;
				if (ingenicoSubcomponent.length !== 0) {
					const ingenicoIframe = ingenicoSubcomponent.find('.ingenico-iframe');

					const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
					const messageEvent = (eventMethod == "attachEvent") ? "onmessage" : "message";
					const eventer = window[eventMethod];
					eventer(messageEvent, function (event) {
						if (typeof event.data === 'string' && event.data.indexOf('/checkout') === 0) {
							const nextStepUrl = ingenicoIframe.contents().find('#nextStepUrl').val();
							if (!window.location.origin) {
								window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
							}
							const url = window.location.origin + window.location.pathname.split('/checkout')[0];
							if (nextStepUrl == undefined) {
								window.location = url + event.data;
							} else {
								window.location = url + nextStepUrl;
							}
						}
					}, false);
				}

				deferred.resolve({});
				return deferred;
			},
			_setUpPayoneSubcomponent: function () {
				const deferred = jQuery.Deferred();

				const widget = this;
				const element = jQuery(widget.element);

				const payoneSubcomponent = element.find('.payone-subcomponent');
				this._payoneSubcomponent = payoneSubcomponent;
				if (payoneSubcomponent.length !== 0) {
					const payoneOptions = widget.options.payone;
					loadExtenalScripts([payoneOptions.scriptUrl]).then(function () {
						payoneSubcomponent.addClass(payoneOptions.classes.jsReady);

						widget._OBTPaymentMethodHelper = new widget.OnlineBankTransferPaymentMethod();

						deferred.resolve({});
					});
				} else {
					deferred.resolve({}); //Nothing to do
				}
				return deferred;
			},
			_setUpEasyCreditSubcomponent: function () {
				const widget = this;
				const element = jQuery(widget.element);
				const easyCreditSubcomponent = element.find('.easycredit-subcomponent');
				const easyCreditForm = element.find('.easycredit-subcomponent-form');
				widget._easyCreditSubcomponent = easyCreditSubcomponent;
				widget._easyCreditForm = easyCreditForm;

				const script = document.createElement('script');
				script.type = 'module';
				script.src = 'https://ratenkauf.easycredit.de/api/resource/webcomponents/v3/easycredit-components/easycredit-components.esm.js';
				document.head.appendChild(script);

				widget._easyCreditForm.css('display', 'flex');

				$(document).on('submit', 'easycredit-checkout', function () {
					$('#epPaymentDetailsForm').submit();
				});

				$(document).on('selectedInstallment', 'easycredit-checkout', function (obj) {
					const value = Number(obj.detail);
					if (value && Number.isInteger(value)) {
						$('#financingTerm').val(value);
					}
				});
			},
			_createPayoneRequestConfig: function (payonePaymentModeConfig, payonePaymentModeGlobalConfig) {
				const paymentModeRequestConfig = {
					mode: payonePaymentModeConfig.mode,
					solution_name: payonePaymentModeConfig.solutionName,
					solution_version: payonePaymentModeConfig.solutionVersion,
					integrator_name: payonePaymentModeConfig.integratorName,
					integrator_version: payonePaymentModeConfig.integratorVersion,
					portalid: payonePaymentModeGlobalConfig.portalId,
					mid: payonePaymentModeGlobalConfig.merchantId,
					aid: payonePaymentModeGlobalConfig.accontId,
					encoding: payonePaymentModeGlobalConfig.encoding,
					hash: payonePaymentModeGlobalConfig.securityKey
				};

				return jQuery.extend(true, {}, this.options.payone.request, paymentModeRequestConfig);
			},
			OnlineBankTransferPaymentMethod: function () {
				const HIDDEN_CLASS = "hidden";
				const FRAME_SELECTOR = '#onlineBankTransferFrame';
				const ALL_INPUTS_SELECTOR = FRAME_SELECTOR + ' #onlineBankTransferForm div.form-group';
				const ACCOUNT_HOLDER_FIELD_SELECTOR = FRAME_SELECTOR + ' #accountHolderForOnlineBankTransfer';
				const BANK_GROUP_FIELD_SELECTOR = FRAME_SELECTOR + ' #bankGroup';

				const setAccountHolderFromAddress = function () {
					const holderName = $('#address\\.firstName').val() + ' ' + $('#address\\.surname').val();
					jQuery(ACCOUNT_HOLDER_FIELD_SELECTOR).val(holderName);
				}

				var isEmptyAccountHolder = function () {
					return jQuery(ACCOUNT_HOLDER_FIELD_SELECTOR) && jQuery(ACCOUNT_HOLDER_FIELD_SELECTOR).val() == "";
				};

				return {
					hideForm: function () {
						jQuery(FRAME_SELECTOR).addClass(HIDDEN_CLASS);
						jQuery(ALL_INPUTS_SELECTOR).addClass(HIDDEN_CLASS);
					},
					showSofortOrGiropayForm: function () {
						jQuery(FRAME_SELECTOR).removeClass(HIDDEN_CLASS);
						jQuery(ACCOUNT_HOLDER_FIELD_SELECTOR).parent().removeClass(HIDDEN_CLASS);
						jQuery(FRAME_SELECTOR + ' #ibanForOnlineBankTransfer').parent().removeClass(HIDDEN_CLASS);
						jQuery(FRAME_SELECTOR + ' #bicForOnlineBankTransfer').parent().removeClass(HIDDEN_CLASS);
					},
					showEpsOrIdealForm: function () {
						jQuery(FRAME_SELECTOR).removeClass(HIDDEN_CLASS);
						jQuery(ACCOUNT_HOLDER_FIELD_SELECTOR).parent().removeClass(HIDDEN_CLASS);
						jQuery(BANK_GROUP_FIELD_SELECTOR).parent().parent().removeClass(HIDDEN_CLASS);
					},
					setupEpsOrIdealForm: function (paymentMethodConfiguration) {
						updateSelectField($(BANK_GROUP_FIELD_SELECTOR), paymentMethodConfiguration.bankGroups);
					},
					setupEmptyAccountHolder: function () {
						if (isEmptyAccountHolder()) {
							if (jQuery('#address\\.firstName').val() != "" && jQuery('#address\\.surname').val() != "") {
								setAccountHolderFromAddress();
							} else {
								// make it user friendly
								// register listeners 'on change' but only with single execution
								jQuery('#address\\.firstName').one('change', function () {
									if (jQuery('#address\\.surname').val() != "") {
										setAccountHolderFromAddress();
									}
								});

								jQuery('#address\\.surname').one('change', function () {
									if ($('#address\\.firstName').val() != "") {
										setAccountHolderFromAddress();
									}
								});
							}
						}
					}
				};
			},
			_getPayonePaymentConfiguration(selectedMethodCode) {
				let selectedConfiguration = {};
				jQuery(kps.payonePaymentMethods).each(function (index, value) {
					if (value.code === selectedMethodCode) {
						selectedConfiguration = value;
					}
				});
				return selectedConfiguration;
			},
			_setupPayoneIframeConfig(iframeConfig) {
				const options = this.options;
				const defaultConfig = options.payone.config;
				const fieldConfig = this._setupPayoneIframeFields(iframeConfig)

				const errorLanguageConfig = {
					language: Payone.ClientApi.Language.de
				};
				if (iframeConfig.errorLanguage === "de") {
					errorLanguageConfig.language = Payone.ClientApi.Language.de;
				} else if (iframeConfig.errorLanguage === "fr") {
					errorLanguageConfig.language = Payone.ClientApi.Language.fr;
				} else if (iframeConfig.errorLanguage === "it") {
					errorLanguageConfig.language = Payone.ClientApi.Language.it;
				}

				let customErrorLanguageConfig = {
					language: options.payone.language[iframeConfig.errorLanguage]
				};
				if (typeof customErrorLanguageConfig.language === 'undefined') {
					customErrorLanguageConfig.language = {};
				}

				return jQuery.extend(true, {}, defaultConfig, fieldConfig, errorLanguageConfig, customErrorLanguageConfig);
			},
			_setupPayoneIframeFields(iframeConfig) {
				const cardNumberFieldConfig = iframeConfig.cardNumberInputConfig;
				const cvc2FieldConfig = iframeConfig.cvc2InputConfig;
				const validMonthFieldConfig = iframeConfig.validMonthInputConfig;
				const validYearFieldConfig = iframeConfig.validYearInputConfig;

				const cardpanFieldConfig = {
					type: cardNumberFieldConfig.inputType.toLowerCase(),
					size: cardNumberFieldConfig.inputLength,
					maxlength: cardNumberFieldConfig.inputMaxChars,
					iframe: {}
				};

				if (cardNumberFieldConfig.iframe.toLowerCase() === 'custom') {
					cardpanFieldConfig.iframe = {
						width: cardNumberFieldConfig.width + "px",
						height: cardNumberFieldConfig.height + "px"
					};
				}
				if (cardNumberFieldConfig.style.toLowerCase() === 'custom') {
					cardpanFieldConfig.style = cardNumberFieldConfig.css;
				}

				const cardcvc2FieldConfig = {
					type: cvc2FieldConfig.inputType.toLowerCase(),
					size: cvc2FieldConfig.inputLength,
					maxlength: cvc2FieldConfig.inputMaxChars,
					iframe: {}
				};

				if (cvc2FieldConfig.iframe.toLowerCase() === 'custom') {
					cardcvc2FieldConfig.iframe = {
						width: cvc2FieldConfig.width + "px",
						height: cvc2FieldConfig.height + "px"
					};
				}
				if (cvc2FieldConfig.style.toLowerCase() === 'custom') {
					cardcvc2FieldConfig.style = cvc2FieldConfig.css;
				}

				const cardexpiremonthFieldConfig = {
					type: validMonthFieldConfig.inputType.toLowerCase(),
					size: validMonthFieldConfig.inputLength,
					maxlength: validMonthFieldConfig.inputMaxChars,
					iframe: {}
				};

				if (validMonthFieldConfig.iframe.toLowerCase() === 'custom') {
					cardexpiremonthFieldConfig.iframe = {
						width: validMonthFieldConfig.width + "px",
						height: validMonthFieldConfig.height + "px"
					};
				}
				if (validMonthFieldConfig.style.toLowerCase() === 'custom') {
					cardexpiremonthFieldConfig.style = validMonthFieldConfig.css;
				}

				const cardexpireyearFieldConfig = {
					type: validYearFieldConfig.inputType.toLowerCase(),
					size: validYearFieldConfig.inputLength,
					maxlength: validYearFieldConfig.inputMaxChars,
					iframe: {}
				};

				if (validYearFieldConfig.iframe.toLowerCase() === 'custom') {
					cardexpireyearFieldConfig.iframe = {
						width: validYearFieldConfig.width + "px",
						height: validYearFieldConfig.height + "px"
					};
				}
				if (validYearFieldConfig.style.toLowerCase() === 'custom') {
					cardexpireyearFieldConfig.style = validYearFieldConfig.css;
				}

				if (iframeConfig.askForCVC2 == false) {
					$("#cvcSection").addClass("hidden");
					$("#cvcSection").hide();
				} else {
					$("#cvcSection").removeClass("hidden");
					$("#cvcSection").show();
				}

				const config = {
					fields: {
						cardpan: cardpanFieldConfig,
						cardcvc2: cardcvc2FieldConfig,
						cardexpiremonth: cardexpiremonthFieldConfig,
						cardexpireyear: cardexpireyearFieldConfig
					},
					defaultStyle: {
						input: iframeConfig.inputDefaultText,
						select: iframeConfig.inputDefaultSelect,
						iframe: {
							height: iframeConfig.iframeDefaulHeight + "px",
							width: iframeConfig.iframeDefaultWidth + "px"
						}
					}
				};

				if (!iframeConfig.showErrorMessage) {
					config.error = "";
				}

				return config;
			},
			isPayonePaymentMode: function (paymentMode) {
				return paymentMode.includes("_payone");
			},
			isIngenicoPaymentMode: function (paymentMode) {
				return !this.isPayonePaymentMode(paymentMode);
			},
			isEasyCreditPaymentMode: function (paymentMode) {
				return (paymentMode.includes('easycredit'));
			},
			_onChange: function (event, data) {
				const value = data.value;

				let showIngenicoForm = false;
				let showEasyCreditSubomponent = false;
				let showPayoneSubcomponent = false;
				let showPayoneSofortOrGiropayForm = false;
				let showSubmitButton = true;

				if (this.isPayonePaymentMode(value)) {
					//Payone
					if (value === 'creditcard_payone') {
						showPayoneSubcomponent = true;
						showSubmitButton = false;
					} else if (value === 'sofortueberweisung_payone') {
						showPayoneSofortOrGiropayForm = true;
					}
				} else {
					//Ingenico
					if (value === 'creditcard') {
						showIngenicoForm = true;
						showSubmitButton = false;

						this._submitIngenicoForm();
					}
				}

				if (this.isEasyCreditPaymentMode(value)) {
					//EasyCredit
					showEasyCreditSubomponent = true;
					showSubmitButton = false;
				}

				if (showIngenicoForm) {
					this._showIngenicoSubcomponent();
				} else {
					this._hideIngenicoSubcomponent();
				}

				if (showEasyCreditSubomponent) {
					this._showEasyCreditSubcomponent();
				} else {
					this._hideEasyCreditSubcomponent();
				}

				if (showPayoneSubcomponent) {
					this._showPayoneSubcomponent();
				} else {
					this._hidePayoneSubcomponent();
				}

				if (showPayoneSofortOrGiropayForm) {
					this._showPayoneSofortOrGiropayForm();
				} else {
					this._hidePayoneSofortOrGiropayForm();
				}

				if (showSubmitButton) {
					this._submitBtn.css('display', 'block');
				} else {
					this._submitBtn.hide();
				}

			},
			_showIngenicoSubcomponent: function () {
				if (typeof this._ingenicoSubcomponent !== 'undefined') {
					this._ingenicoSubcomponent.show();
				}
			},
			_hideIngenicoSubcomponent: function () {
				if (typeof this._ingenicoSubcomponent !== 'undefined') {
					this._ingenicoSubcomponent.hide();
				}
			},
			_showEasyCreditSubcomponent: function () {
				if (typeof this._easyCreditSubcomponent !== 'undefined') {
					const widget = this;
					widget._easyCreditSubcomponent.css('display', 'flex');

				}
			},
			_hideEasyCreditSubcomponent: function () {
				if (typeof this._easyCreditSubcomponent !== 'undefined') {
					this._easyCreditSubcomponent.hide();
				}
			},
			_showPayoneSubcomponent: function () {
				if (typeof this._payoneSubcomponent !== 'undefined') {
					if (this._payoneSubcomponentReady !== true) {
						this._initPayoneSubcomponent();
						this._payoneSubcomponentReady = true;
					}

					this._payoneSubcomponent.show();
				}
			},
			_initPayoneSubcomponent: function () {
				const widget = this;
				const element = widget.element;

				const paymentCode = 'creditcard_payone';

				const payonePaymentModeConfig = widget._getPayonePaymentConfiguration(paymentCode);
				const payonePaymentModeGlobalConfig = payonePaymentModeConfig.globalConfigurationData;

				const payoneRequestConfiguration = widget._createPayoneRequestConfig(payonePaymentModeConfig, payonePaymentModeGlobalConfig);

				const payoneIframeConfig = widget._setupPayoneIframeConfig(payonePaymentModeConfig);

				const hostedIframe = new Payone.ClientApi.HostedIFrames(payoneIframeConfig, payoneRequestConfiguration);
				hostedIframe.enableCardTypeDetection();

				const button = widget._payoneSubcomponent.find('.payone-subcomponent--form-submit');
				widget._on(button, {
					click: function () {
						if (hostedIframe.isComplete()) {
							hostedIframe.creditCardCheck('payoneCheckCallback');
						}
					}
				});

				window.payoneCheckCallback = function (response) {
					if (response.status === "VALID") {
						element.find("#pseudocardpan").val(response.pseudocardpan);
						element.find("#vCardType").val(response.cardtype);
						element.find("#vCardHolderName").val(element.find('#payoneCardholdername').val());
						element.find("#vTruncatedcardpan").val(response.truncatedcardpan);
						element.find("#vCardexpiredate").val(response.cardexpiredate);
						widget._submitBtn.click();
					}
				}
			},
			_hidePayoneSubcomponent: function () {
				if (typeof this._payoneSubcomponent !== 'undefined') {
					this._payoneSubcomponent.hide();
				}
			},
			_showPayoneSofortOrGiropayForm: function () {
				if (typeof this._OBTPaymentMethodHelper !== 'undefined') {
					this._OBTPaymentMethodHelper.showSofortOrGiropayForm();
					this._OBTPaymentMethodHelper.setupEmptyAccountHolder();
				}
			},
			_hidePayoneSofortOrGiropayForm: function () {
				if (typeof this._OBTPaymentMethodHelper !== 'undefined') {
					this._OBTPaymentMethodHelper.hideForm();
				}
			},
			paymentmode: function () {
				let paymentmode = null;
				const checkedInputs = this._inputs.filter(':checked');
				if (checkedInputs.length !== 0) {
					const checkedInput = jQuery(checkedInputs[0]);
					paymentmode = checkedInput.val();
				}

				return paymentmode;
			},
			getPaymentProviders: function () {
				let ingenico = false;
				let payone = false;
				let easycredit = false;

				const widget = this;
				jQuery.each(this._inputs, function () {
					const input = jQuery(this);
					const paymentMode = input.val();

					if (widget.isIngenicoPaymentMode(paymentMode)) {
						ingenico = true;
					}

					if (widget.isPayonePaymentMode(paymentMode)) {
						payone = true;
					}

					if (widget.isEasyCreditPaymentMode(paymentMode)) {
						easycredit = true;
					}
				});

				return {
					ingenico: ingenico,
					payone: payone,
					easycredit: easycredit
				}
			},
			_submitIngenicoForm: function () {
				if (!this._ingenicoFormformSubmited) {
					this.element.find('.ingenico-form').submit();

					this._ingenicoFormformSubmited = true;
				}
			}

		});

		jQuery('.ep-responsive-checkout-payment-component').checkoutPaymentComponent();
	});
}
