import colorbox from 'jquery-colorbox';
import jQuery from 'jquery';
import dotdotdot from 'dotdotdot';
import jQueryUi from 'jquery-ui';
import _ from 'underscore';

import {getLocation} from './locationService';
import jQueryMouseWheel from 'jquery-mousewheel';
import customScrollbarPlugin from 'malihu-custom-scrollbar-plugin';

const speditionDeliveryModes = ["servicedelivery", "twomanhandling"];


jQuery.widget('ep.posResultsList', {
	options: {
		searching: false,
		validationSucceeded: true,
		results: [],
		visible: true,
		classes: {
			emptyTextContainer: 'pos-results-list__empty-text',
			loadingElm: 'pos-results-list__loading',
			noResultsTextContainer: 'pos-results-list__no-results-text',
			posInfoWrapper: 'pos-results-list__pos-info-wrapper',
			posLogo: 'pos-results-list__pos-info-logo',
			posName: 'pos-results-list__pos-info-name',
			posDistanceElm:  'pos-results-list__pos-info-dist',
			posInfoElm: 'pos-results-list__pos-info',
			posAddressElm:  'pos-results-list__pos-address',
			posOpeningScheduleElm: 'pos-results-list__pos-schedule',
			posSidebarElm: 'pos-results-list__pos-sidebar',
			posOpeningScheduleEntry: 'pos-results-list__pos-schedule-entry',
			posOpeningScheduleDay: 'pos-results-list__pos-schedule-day',
			posOpeningScheduleTimes: 'pos-results-list__pos-schedule-times',
			posOpeningSchedulePeriod1: 'pos-results-list__pos-schedule-period1',
			posOpeningSchedulePeriod2: 'pos-results-list__pos-schedule-period2',
			posResultsList: 'pos-results-list__pos_list',
			posResultsListEmpty: 'pos-results-list--no-results',
			posResultsListInitial: 'pos-results-list--no-search',
			posResultsListSearching: 'pos-results-list--searching',
			posResultsListInvisible: 'pos-results-list--invisible',
			posStockLevelElm: 'pos-results-list__pos-stock-level',
			posStockLevelFirstElm: 'pos-results-list__pos-stock-level--first',
			posStockMsgElm: 'pos-results-list__stock-message',
			resultItem: 'pos-results-list__result-item',
			resultItemFooter: 'pos-results-list__result-footer',
			resultItemSelectButton: 'pos-results-list__select-button',
			resultItemSelectButtonAddToCart: 'pos-results-list__select-button--add-to-cart',
			resultsList: 'pos-results-list__list',
			posResultsListInitialValidationFailed: 'pos-results-list--validation-failed',
			validationFailedTextContainer: 'pos-results-list__validation-text-wrapper'
		},
		addToCartButtons: false, 
		texts: {
			distancePrefix: __('posresultslist.distanceprefix'),
			emptyText: 'No results',
			noResultsText: 'No results',
			selectText: __('posresultslist.selecttext'),
			validationFailed: 'Validation failed'
		}
	},
	_create: function(){
		this._createBasicElements();
	},
	_createBasicElements(){
		const classes = this.options.classes;
		
		const posResultsList = this.element;
		posResultsList.addClass(classes.posResultsList);
		
		const resultsList = jQuery('<div/>');
		resultsList.addClass(classes.resultsList);
		posResultsList.append(resultsList);
		
		const emptyTextContainer = jQuery('<div/>');
		emptyTextContainer.addClass(classes.emptyTextContainer);
		posResultsList.append(emptyTextContainer);
		
		const noResultsTextContainer = jQuery('<div/>');
		noResultsTextContainer.addClass(classes.noResultsTextContainer);
		posResultsList.append(noResultsTextContainer);
		
		const validationFailedTextContainer = jQuery('<div/>');
		validationFailedTextContainer.addClass(classes.validationFailedTextContainer);
		posResultsList.append(validationFailedTextContainer);
		
		const loadingElm = jQuery('<div/>');
		loadingElm.addClass(classes.loadingElm);
		posResultsList.append(loadingElm);
		
		this._elmCache = {
			emptyTextContainer: emptyTextContainer,
			loadingElm: loadingElm,
			noResultsTextContainer: noResultsTextContainer,
			resultsList: resultsList,
			posResultsList: posResultsList,
			validationFailedTextContainer: validationFailedTextContainer
		};
	},
	_setOptions: function(options) {
		if(options.searching === true){
			options.validationSucceeded = true;
			options.results = [];
		}else if(options.validationSucceeded === false){
			options.searching = false;
			options.results = [];
		}
		
		this._superApply([options]);
		this._refresh();
	},
	_refresh: function() {
		this._refreshClasses();
		this._refreshTexts();
		this._refreshResults();
	},
	_refreshClasses: function(){
		const options = this.options;
		const classes = options.classes;
		
		const elmCache = this._elmCache;
		const posResultsList = elmCache.posResultsList;
		
		if(this._firstRefresh !== false){
			posResultsList.addClass(classes.posResultsListInitial);
			
			this._firstRefresh = false;
		}else{
			posResultsList.removeClass(classes.posResultsListInitial);
		}
		
		if(options.visible){
			posResultsList.removeClass(classes.posResultsListInvisible);
		}else{
			posResultsList.addClass(classes.posResultsListInvisible);
		}
		
		const searching = options.searching;
		if(searching){
			posResultsList.addClass(classes.posResultsListSearching);
		}else{
			posResultsList.removeClass(classes.posResultsListSearching);
		}
		
		const results = options.results;
		if(results.length === 0){
			posResultsList.addClass(classes.posResultsListEmpty);
		}else{
			posResultsList.removeClass(classes.posResultsListEmpty);
		}
		
		if(options.validationSucceeded){
			posResultsList.removeClass(classes.posResultsListInitialValidationFailed);
		}else{
			posResultsList.addClass(classes.posResultsListInitialValidationFailed);
		}
	},
	_refreshTexts: function(){
		const elmCache = this._elmCache;
		const texts = this.options.texts;
		
		elmCache.emptyTextContainer.text(texts.emptyText);

		elmCache.validationFailedTextContainer.text(texts.validationFailed);
		elmCache.noResultsTextContainer.text(texts.noResultsText);
	},
	_refreshResults: function(){
		const component = this;
		
		const options = this.options;
		const results = options.results;
		
		const classes = options.classes;
		const texts = options.texts;
		
		const elmCache = this._elmCache;
		const resultsList = elmCache.resultsList;
		const posResultsList = elmCache.posResultsList;
		
		// Remove scrollr
		posResultsList.mCustomScrollbar('destroy');
		
		// Clear
		resultsList.children().remove();
		
		// Prepare new results
		const resultItems = [];
		jQuery.each(results, function(index, result){
			
			const resultItem = jQuery('<div/>');
			resultItem.addClass(classes.resultItem);
			
			const posInfoElement = component._createPosInfoElement.apply(component, [result]);
			resultItem.append(posInfoElement);
			
			// Footer
			const resultItemFooter= jQuery('<div/>');
			resultItemFooter.addClass(classes.resultItemFooter);
			resultItem.append(resultItemFooter);
			
			const resultItemSelectButton = jQuery('<button/>');
			resultItemSelectButton.attr('type', 'button');
			if(options.addToCartButtons){
				resultItemSelectButton.addClass(classes.resultItemSelectButtonAddToCart);
			}
			
			resultItemSelectButton.addClass(classes.resultItemSelectButton);
			resultItemSelectButton.text(texts.selectText);
			resultItemFooter.append(resultItemSelectButton);

			resultItemFooter.click(function (event) {
				component._trigger('selected', event, result);
			});

			resultItems.push(resultItem);
		});

		resultsList.append(resultItems);


		if (results.length !== 0) {
			posResultsList.mCustomScrollbar({
				theme: 'dark',
				scrollInertia: 0,
				callbacks: {
					onInit: () => this._reorderScrollbarRail()
				}
			});
		}
	},
	// ELPA-13030 bugfix for JQuery > 3.5 -> https://github.com/malihu/malihu-custom-scrollbar-plugin/issues/663
	_reorderScrollbarRail: function () {
		const element = this.element;
		const scrollbar = element.find('.mCustomScrollbar');
		const draggerRail = $(scrollbar).find('.mCSB_draggerRail');
		const draggerContainer = $(scrollbar).find('.mCSB_draggerContainer');
		if (draggerRail.length > 0 && draggerRail.parent().hasClass("mCSB_dragger")) {
			draggerContainer.append(draggerRail);
		}
	},
	_createPosInfoElement: function (pos) {
		const options = this.options;
		const classes = options.classes;
		const texts = options.texts;

		const posInfoWrapper = jQuery('<div/>');
		posInfoWrapper.addClass(classes.posInfoWrapper);

		// Logo
		if (typeof pos.logoUrl !== 'undefined') {
			let posIdentifier = null;
			if (typeof pos.name !== 'undefined' && pos.name.includes("MEDIMAX")) {
				const posNameElm = jQuery('<span/>');
				posNameElm.text(pos.name);
				posNameElm.addClass(classes.posName);

				posIdentifier = posNameElm;
			} else {
				const posLogo = jQuery('<img/>');
				posLogo.attr('src', pos.logoUrl);
				posLogo.attr('alt', pos.name);
				posLogo.attr('title', pos.name);
				posLogo.addClass(classes.posLogo);
				
				posIdentifier = posLogo;
			}
			posInfoWrapper.append(posIdentifier);
		}
			
		const formattedDistance = pos.formattedDistance;
		if(typeof formattedDistance !== 'undefined'){
			const posDistanceElm = jQuery('<div/>');
			posDistanceElm.addClass(classes.posDistanceElm);
			posDistanceElm.text(texts.distancePrefix + formattedDistance);
				
			posInfoWrapper.append(posDistanceElm);
		}
			
		// Info
		const posInfoElm = jQuery('<div/>');
		posInfoElm.addClass(classes.posInfoElm);
		posInfoWrapper.append(posInfoElm);
			
		// Address
		const posAddressElm = jQuery('<div/>');
		posAddressElm.addClass(classes.posAddressElm);

		const storeAddress = pos.address;
		posAddressElm.append(storeAddress.street + '<br/>' + storeAddress.zip + ' ' + storeAddress.town);

		posInfoElm.append(posAddressElm);
			
		//
		// Sidebar
		//
		const posSidebarElm = jQuery('<div/>');
		posSidebarElm.addClass(classes.posSidebarElm);

		posInfoWrapper.append(posSidebarElm);

		// Opening schedule
		const openingHours = pos.openingHours;
		if(typeof openingHours !== 'undefined' && openingHours.length !== 0){
			const posOpeningScheduleElm = jQuery('<table/>');
			posOpeningScheduleElm.addClass(classes.posOpeningScheduleElm);
			
			const posOpeningScheduleBody = jQuery('<tbody/>');
			posOpeningScheduleElm.append(posOpeningScheduleBody);
			
			const openingScheduleRows = openingHours.map(function(weekDay){
				const hasPause = (typeof weekDay.startPauseTime !== 'undefined') && (typeof weekDay.endPauseTime !== 'undefined');
					
				const rowElm = jQuery('<tr/>');
				rowElm.addClass(classes.posOpeningScheduleEntry);
					
				const dayCell = jQuery('<td/>');
				dayCell.addClass(classes.posOpeningScheduleDay);
				dayCell.text(weekDay.day);
					
				rowElm.append(dayCell);
					
				const timesCell = jQuery('<td/>');
				timesCell.addClass(classes.posOpeningScheduleTimes);
					
				let period1Str = undefined;
				let period2Str = undefined;
					
				if(hasPause){
					period1Str = weekDay.openingTime + ' - ' + weekDay.startPauseTime;
					period2Str = weekDay.endPauseTime + ' - ' + weekDay.closingTime;
				}else{
					period1Str = weekDay.openingTime  + ' - ' + weekDay.closingTime;
				}
					
				const period1Elm = jQuery('<div/>');
				period1Elm.addClass(classes.posOpeningSchedulePeriod1);
				period1Elm.text(period1Str);
					
				timesCell.append(period1Elm);
					
				if(typeof period2Str !== 'undefined'){
					const period2Elm = jQuery('<div/>');
					period2Elm.addClass(classes.posOpeningSchedulePeriod2);
					period2Elm.text(period2Str);
					
					timesCell.append(period2Elm);
				}
		
				rowElm.append(timesCell);
					
				return rowElm;
			});
				
			posOpeningScheduleBody.append(openingScheduleRows);
				
			posSidebarElm.append(posOpeningScheduleElm);
		}
		
		// Stock
		const posStockLevelElm = jQuery('<div/>');
		posStockLevelElm.addClass(classes.posStockLevelElm + ' ' + classes.posStockLevelFirstElm + ' ' + pos.stockClass);
		posInfoWrapper.append(posStockLevelElm);

		const isExclusive = pos.exclusiveStockMessage
		if(!isExclusive) {
			
			const isPickupModeAvailable = pos.stockMessagePickup;
			if (isPickupModeAvailable) {
				const posStockMsgPickupElm = jQuery('<div/>');
				posStockMsgPickupElm.addClass(classes.posStockMsgElm);
				posStockMsgPickupElm.text(pos.stockMessagePickup);
				posStockLevelElm.append(posStockMsgPickupElm);
			}
			
			const isLocalPickupModeAvailable = pos.stockMessageLocalPickup;
			if (isLocalPickupModeAvailable) {
				const posStockLevelElm = jQuery('<div/>');
				posStockLevelElm.addClass(classes.posStockLevelElm + ' ' + pos.stockAvailableInPlaceClass);
				posInfoWrapper.append(posStockLevelElm);
			
				const posStockMsgPickupElm = jQuery('<div/>');
				posStockMsgPickupElm.addClass(classes.posStockMsgElm);
				posStockMsgPickupElm.text(pos.stockMessageLocalPickup);
				posStockLevelElm.append(posStockMsgPickupElm);
			}
			
			const isDeliveryModeAvailable = pos.stockMessageDelivery;
			if (isDeliveryModeAvailable) {
				const posStockLevelElm = jQuery('<div/>');
				posStockLevelElm.addClass(classes.posStockLevelElm + ' ' + pos.stockClass);
				posInfoWrapper.append(posStockLevelElm);
	
				const posStockMsgDeliveryElm = jQuery('<div/>');
				posStockMsgDeliveryElm.addClass(classes.posStockMsgElm);
				posStockMsgDeliveryElm.text(pos.stockMessageDelivery);
				posStockLevelElm.append(posStockMsgDeliveryElm);
			}
			
		} else {
			const posStockLevelElm = jQuery('<div/>');
			posStockLevelElm.addClass(classes.posStockLevelElm + ' ' + pos.stockClass);
			posInfoWrapper.append(posStockLevelElm);
	
			const posStockMsgExclusiveElm = jQuery('<div/>');
			posStockMsgExclusiveElm.addClass(classes.posStockMsgElm);
			posStockMsgExclusiveElm.text(pos.exclusiveStockMessage);
			posStockLevelElm.append(posStockMsgExclusiveElm);
		}
			
		return posInfoWrapper;
	}
});

/*
 * Params
 * classes: {
 *     productInfoElm,
 *     productImageWrapperElm,
 *     eekLabelElm,
 *     productImageElm,
 *     productInfoContentElm,
 *     productBrandElm,
 *     productNameElm,
 *     productDescriptionWrapperElm,
 *     productDescriptionElm,
 *     productPriceElm
 * }
 *
 * product: {
 *     eek: {
 *         cssClass,
 *         letter,
 *         sign
 *     },
 *     imageAlt,
 *     imageUrl,
 *     brandName,
 *     name,
 *     description,
 *     offerStr
 * }
 *
 * texts: {
 *   productInfo: {
 *     eekLink
 *   }
 * }
 */
function createProductInfoElm(product, classes, texts, eekSvgSource){
	const productImageWrapperElm = jQuery('<div/>');
	productImageWrapperElm.addClass(classes.productImageWrapperElm);

	const hasEekLabel = typeof product.eek !== 'undefined';
	if(hasEekLabel){
		const eek = product.eek;

		//EEK Label
		const eekLabelLinked = product.eek.energyLabel !== null && product.eek.energyLabel.length !== 0;

		const isEu21 = product.eek.eu21 == true;

		const eekLabelElm = jQuery(eekLabelLinked ? '<a target="_blank"/>' : '<div/>');
		if (eekLabelLinked) {
			eekLabelElm.attr('href', product.eek.energyLabel);
		}

		eekLabelElm.addClass(isEu21 ? classes.eekLabelElmEu21 : classes.eekLabelElm);
		if(isEu21){
			const eekLabelImageElm = jQuery('<img/>');

			const svgSrc = eekSvgSource + eek.cssClass + '.svg';
			eekLabelImageElm.attr('src', svgSrc);
			
			eekLabelElm.append(eekLabelImageElm);
		} else {
			eekLabelElm.addClass(eek.cssClass);
			eekLabelElm.text(eek.letter + eek.sign);
		}
		
		productImageWrapperElm.append(eekLabelElm);

		// EEK Link
		let eekLinkUrl = product.eek.energyDataSheet;
		if (eekLinkUrl === null || eekLinkUrl.length === 0) {
			eekLinkUrl = product.url + '#classifications-tab';
		}

		const eekLinkElm = jQuery('<a target="_blank"/>');
		eekLinkElm.addClass(classes.eekLink);
		eekLinkElm.attr('href', eekLinkUrl);
		eekLinkElm.text(texts.productInfo.eekLink);

		const eekLinkSectionElm = jQuery('<div/>');
		eekLinkSectionElm.addClass(classes.eekLinkSection);
		eekLinkSectionElm.append(eekLinkElm);

		productImageWrapperElm.append(eekLinkSectionElm);
	}

	const productPictureElm = createPictureElement(classes.productImageElm, product.imageAlt, product.imageAlt, product.imageUrl);
	productImageWrapperElm.append(productPictureElm);
	
	const productInfoContentElm = jQuery('<div/>');
	productInfoContentElm.addClass(classes.productInfoContentElm);

	const productBrandElm = jQuery('<div/>');
	productBrandElm.addClass(classes.productBrandElm);
	productBrandElm.text(product.brandName);
	productInfoContentElm.append(productBrandElm);
	
	const productNameElm = jQuery('<div/>');
	productNameElm.addClass(classes.productNameElm);
	productNameElm.text(product.name);
	productInfoContentElm.append(productNameElm);
	
	const productDescriptionWrapperElm = jQuery('<div/>');
	productDescriptionWrapperElm.addClass(classes.productDescriptionWrapperElm);
	productInfoContentElm.append(productDescriptionWrapperElm);
	
	const productDescriptionElm = jQuery('<div/>');
	productDescriptionElm.addClass(classes.productDescriptionElm);
	productDescriptionElm.append(product.description);
	productDescriptionWrapperElm.append(productDescriptionElm);
	productDescriptionElm.dotdotdot({watch: true});
	
	const productPriceElm = jQuery('<div/>');
	productPriceElm.addClass(classes.productPriceElm);
	productPriceElm.append(product.offerStr);
	productInfoContentElm.append(productPriceElm);

	const productInfoElm = jQuery('<div/>');
	productInfoElm.addClass(classes.productInfoElm);
	if (hasEekLabel) {
		productInfoElm.addClass(classes.productInfoElmWithEek);
	}

	productInfoElm.append(productImageWrapperElm);
	productInfoElm.append(productInfoContentElm);


	return productInfoElm;
}
/*
 * Param
 * imageClass,
 * imageAlt,
 * imageTitle,
 * imageUrl: {
 * 	    imageSize200,
 * 		imageSize400
 * }
 */
function createPictureElement(imageClass, imageAlt, imageTitle, imageUrl) {
	const productPictureElm = jQuery('<picture/>');
	const urlArray = [];
    const urlSizes = [200, 400];
	urlArray.push(imageUrl.imageSize200, imageUrl.imageSize400);


	productPictureElm.append(createSourceElement(urlArray, urlSizes, 200, 'webp'));
	productPictureElm.append(createSourceElement(urlArray, urlSizes, 200, 'jpg'));

	const productImageElm = jQuery('<img/>');
	productImageElm.addClass(imageClass);
	productImageElm.attr('alt', imageAlt);
	productImageElm.attr('title', imageAlt);
	productImageElm.attr('srcset', imageUrl.imageSize200);
	productPictureElm.append(productImageElm);

	return productPictureElm;
}

/*
 * Params
 * imageUrl,
 * imageSizes,
 * sizes,
 * imageType
 */

function createSourceElement( imageUrl, imageSizes, sizes, imageType) {
	const productSourceElm = jQuery('<source/>');
	let srcset="";

	for (let urlsIndex in imageUrl) {
		let url = imageUrl[urlsIndex];
        let urlSize = imageSizes[urlsIndex];

		if (urlsIndex != (imageUrl.length - 1)) {
		    srcset += url + ' ' + urlSize + 'w, ';
		} else {
		    srcset += url + ' ' + urlSize + 'w';
		}
	}

	if (imageType !== 'jpg'){
		srcset = srcset.replace(/jpg/g, imageType);
	}

	productSourceElm.attr('srcset', srcset);
	productSourceElm.attr('sizes', sizes+'px');
	productSourceElm.attr('imageType', 'type/'+imageType);

	return productSourceElm;
}

/**
 * classes: {
 *     staticContentForm,
 *     staticSearchQuery,
 *     staticSubmitButton
 * }
 */
function createStaticContentForm(initialTextSearchValue, placeholder, classes){
	const staticContentForm = jQuery('<form/>');
	staticContentForm.addClass(classes.staticContentForm);
	staticContentForm.attr('action', '#');
	staticContentForm.attr('onsubmit', 'event.preventDefault();');
	staticContentForm.attr('method', 'GET');

	const staticSearchQuery = jQuery('<input/>');
	staticSearchQuery.addClass(classes.staticSearchQuery);
	staticSearchQuery.attr('type', 'search');
	staticSearchQuery.attr('placeholder', placeholder);
	staticContentForm.append(staticSearchQuery);
	
	staticSearchQuery.val(initialTextSearchValue);
	
	const staticSubmitButton = jQuery('<button/>');
	staticSubmitButton.addClass(classes.staticSubmitButton);
	staticSubmitButton.attr('type', 'submit');
	staticContentForm.append(staticSubmitButton);
	
	return {
		staticContentForm: staticContentForm,
		staticSearchQuery: staticSearchQuery,
		staticSubmitButton: staticSubmitButton
	};
}

class SelectPointOfServiceOverlay{
	constructor(options){
		const defaultOptions = {
			product: {
				code: undefined,
				name: undefined,
				description: undefined,
				brandName: undefined,
				eek: undefined, // {sign: ..., cssClass: ..., letter: ...}
				offerStr: undefined,
				imageUrl: undefined,
				imageAlt: undefined
			},
			initialTextSearchValue: '',
			zipSearchOnly: false,
			doInitialSearch: true,
			useInitialLocationSearch: true,
			serviceProducts: [], // List of codes
			inStoreOnly: false,
			deliveryModeLabel: undefined,
			mobileTitleUntil: 700,
			texts: {
				emptyText: __('selectposoverlay.emptytext'),
				zipSearchOnlyEmptyText: __('selectposoverlay.zipsearchonly.emptytext'),
				title: __('selectposoverlay.title'),
				titleMobile: __('selectposoverlay.title'),
				productInfo: {
					eekLink: __('selectposoverlay.productinfo.eeklink'),
				},
				zipSearchOnlyTitle: __('selectposoverlay.zipSearchOnlyTitle'),
				zipSearchOnlyTitleMobile: __('selectposoverlay.zipSearchOnlyTitleMobile'),
				queryPlaceholder: __('selectposoverlay.queryplaceholder'),
				zipSearchOnlyQueryPlaceholder: __('selectposoverlay.zipsearchonly.queryplaceholder'),
				locationButton: __('selectposoverlay.locationbutton'),
				noResults: {
					defaultMode: {
						fallback: __('selectposoverlay.noresultstext'),
						deliveryModeLabels: {}
					},
					zipSearchOnly: {
						fallback: __('selectposoverlay.zipsearchonly.noresultstext'),
						deliveryModeLabels: {
							servicedelivery: __('selectposoverlay.zipsearchonly.noresultstext.servicedelivery')
						}
					}
				},
				validationFailed: __('selectposoverlay.validationfailed')
			},
			isAddToCartOverlay: false,
			classes: {
				selectPosOverlayWrapper: 'select-pos-overlay',
				staticContentWrapper: 'select-pos-overlay-static',
				staticContentForm: 'select-pos-overlay-form',
				staticLocationBtn: 'select-pos-location-btn',
				staticSearchQuery: 'select-pos-overlay-query',
				staticSubmitButton: 'select-pos-overlay-submit',
				productInfoElm: 'select-pos-product-info',
				productInfoElmWithEek: 'select-pos__product-info--has-eek',
				productImageWrapperElm: 'select-pos-product-image-wrapper',
				productImageElm: 'select-pos-product-image',
				productInfoContentElm: 'select-pos-product-content',
				productBrandElm: 'select-pos-product-brand',
				productNameElm : 'select-pos-product-name',
				productDescriptionWrapperElm: 'select-pos-product-descr-wrapper',
				productDescriptionElm: 'select-pos-product-descr',
				productPriceElm: 'select-pos-product-price',
				eekLabelElm: 'select-pos-product-energy-label eek',
				eekLabelElmEu21: 'select-pos-product-energy-label eu21',
				eekLinkSection: 'select-pos__product-energy-link-section',
				eekLink: 'select-pos__product-energy-link',
			},
			eekSvgSource: '/_ui/epresponsive/theme-ep/svg/energy_efficiency_',
		};
		
		jQuery.extend(true, defaultOptions, options);
		
		this.options = defaultOptions;
		
		this.state = {
			opened: false,
			selected: false
		};
		
		this.overlayContent = this._createPosOverlayContent();
		this._setUpOverlayListeners(this.overlayContent);
	}
	_createPosOverlayContent(){
		const overlay = this;
		const options = this.options;
		const product = this.options.product;
		const classes = options.classes;
		const texts = options.texts;
		
		const selectPosOverlayWrapper = jQuery('<div/>');
		selectPosOverlayWrapper.addClass(classes.selectPosOverlayWrapper);
		
		// Static content
		const staticContentWrapper = jQuery('<div/>');
		staticContentWrapper.addClass(classes.staticContentWrapper);
		selectPosOverlayWrapper.append(staticContentWrapper);
		
		// Product info
		const productInfoElm = createProductInfoElm(product, classes, texts, this.options.eekSvgSource);
		staticContentWrapper.append(productInfoElm);
		
		// Location
		let staticLocationBtn = null;
		
		const isZipSearchOnly = options.zipSearchOnly;
		if(!isZipSearchOnly){
			staticLocationBtn = jQuery('<button/>');
			staticLocationBtn.attr('type', 'button');
			staticLocationBtn.addClass(classes.staticLocationBtn);
			staticLocationBtn.text(texts.locationButton);
			staticContentWrapper.append(staticLocationBtn);
		}

		// Form
		const placeholder = isZipSearchOnly?options.texts.zipSearchOnlyQueryPlaceholder:options.texts.queryPlaceholder;
		const staticContentFormElements = createStaticContentForm(options.initialTextSearchValue, placeholder, classes);
		staticContentWrapper.append(staticContentFormElements.staticContentForm);
		
		// Results
		const noResultTexts = texts.noResults[isZipSearchOnly?'zipSearchOnly':'defaultMode'];
		
		const noResultsTextForDeliveryModeLabel = noResultTexts['deliveryModeLabels'][options.deliveryModeLabel];
		const noResultsTextForElm = typeof noResultsTextForDeliveryModeLabel !== 'undefined'?noResultsTextForDeliveryModeLabel:(noResultTexts.fallback);
		
		const posResultsList = jQuery('<div/>');
		selectPosOverlayWrapper.append(posResultsList);
		posResultsList.posResultsList({
			addToCartButtons: options.isAddToCartOverlay,
			texts: {
				emptyText: isZipSearchOnly?texts.zipSearchOnlyEmptyText:texts.emptyText,
				noResultsText: noResultsTextForElm,
				validationFailed: texts.validationFailed
			}
		});
		
		posResultsList.on('posresultslistselected', function(event, result){
			overlay.deferred.resolve(result);
		});
		
		return {
			root: selectPosOverlayWrapper,
			staticContentWrapper: staticContentWrapper,
			posResultsList: posResultsList,
			staticSubmitButton: staticContentFormElements.staticSubmitButton,
			staticSearchQuery: staticContentFormElements.staticSearchQuery,
			staticContentForm: staticContentFormElements.staticContentForm,
			staticLocationBtn: staticLocationBtn
		};
	}
	_setUpOverlayListeners(overlayContent){
		const overlay = this;
		
		// Location search
		if(!this.options.zipSearchOnly){
			jQuery(overlayContent.staticLocationBtn).click(overlay._onLocationSearchSubmit.bind(this));
		}
			
		// Form Submit
		jQuery(overlayContent.staticContentForm).on('submit', function(){
			const query = overlayContent.staticSearchQuery.val();
			overlay._onSearchQuerySubmit.apply(overlay, [query]);
		});
	}
	_onSearchQuerySubmit(query){
		 this._doRemoteTextSearch(query);
	}
	_onLocationSearchSubmit(){
		this._doRemoteLocationSearch();
	}
	_doRemoteTextSearch(query){
		const url = '/store-pickup/' + this.options.product.code + '/api/pointOfServices';
		
		const data = {
			query: query
		};
		
		return this._doRemoteSearch(url, data, true);
	}
	_doRemoteLocationSearch(){
		const overlay = this;
		getLocation()
		.then(function(location){
			const url = '/store-pickup/' + overlay.options.product.code + '/api/pointOfServices/location';
			
			const data = {
				lat: location.lat,
				lng: location.lng
			};
			
			return overlay._doRemoteSearch.apply(overlay, [url, data, false]);
			
		}, function(err){
			console.error(err);
		});
		
	}
	_doRemoteSavedLocationSearch(){
		const url = '/store-pickup/' + this.options.product.code + '/api/pointOfServices/location';
		
		const data = {};
		return this._doRemoteSearch(url, data, true);
	}
	_setStartSearchStatus(){
		const options = this.options;

		const overlayContent = this.overlayContent;
		
		// Disable inputs
		const inputsToDisable = ['staticSubmitButton', 'staticSearchQuery'];
		if(!options.zipSearchOnly){
			inputsToDisable.push('staticLocationBtn');
		}
		
		jQuery.each(inputsToDisable, function(index, elementKey){
			overlayContent[elementKey].prop('disabled', true);
		});
		
		overlayContent.posResultsList.posResultsList('option', {
			searching: true
		});
		
		jQuery.colorbox.resize();
	}
	_setEndSearchStatus(results, enableLocationBtn){
		const overlayContent = this.overlayContent;
		const options = this.options;
		const classes = options.classes;
		
		// Enable inputs
		const inputs = ['staticSubmitButton', 'staticSearchQuery'];
		if(!options.zipSearchOnly && (typeof enableLocationBtn === 'undefined' || enableLocationBtn === true)){
			inputs.push('staticLocationBtn');
		}
		
		jQuery.each(inputs, function(index, elementKey){
			overlayContent[elementKey].prop('disabled', false);
		});
		
		
		const posResultsList = overlayContent.posResultsList;
		posResultsList.posResultsList('option', {
			searching: false,
			results: results
		});
		
		jQuery.colorbox.resize();
	}
	_doRemoteSearch(url, data, enableLocationBtn){
		const isLocationSearch = !enableLocationBtn;
		
		const overlay  = this;
		const options = overlay.options;
		
		this._setStartSearchStatus();
		
		if(options.inStoreOnly !== false){
			data.storeFilterValue = options.inStoreOnly;
		}
		
		if(typeof options.deliveryModeLabel !== 'undefined'){
			data.deliveryMode = options.deliveryModeLabel;
		}
		
		if(this.options.serviceProducts.length !== 0){
			data.serviceProducts = options.serviceProducts.join(',');
		}
		
		const zipSearchOnly = options.zipSearchOnly;
		if(zipSearchOnly){
			data.zipSearchOnly = true;
		}
		
		const storeLocation = !options.zipSearchOnly && isLocationSearch;
		data.storeLocation = isLocationSearch;
		
		jQuery.ajax({
			 url: url,
			 data: data,
			 method: (storeLocation || zipSearchOnly)?'POST':'GET',
			cache: false
		 })
		 .then(function(data){
			 const validationSucceeded = typeof data.isValidZipCode === 'undefined'?true:data.isValidZipCode;
			 const results = data.results;
			 
			 overlay._setResults.apply(overlay, [validationSucceeded, results]);
			 overlay._setEndSearchStatus.apply(overlay, [results, enableLocationBtn]);
		 }, function(){
			 const results = [];
			 overlay._setEndSearchStatus.apply(overlay, [results, enableLocationBtn]);
		 });
	}
	_setResults(validationSucceeded, results){
		const overlayContent = this.overlayContent;
		const posResultsList = overlayContent.posResultsList;
		
		posResultsList.posResultsList('option', {
			searching: false,
			validationSucceeded: validationSucceeded,
			results: results
		});
	}
	_getTitle(){
		const dfd  = jQuery.Deferred();
		
		const options = this.options;
		const texts = options.texts;
		
		import('modernizr').then(function(Modernizr){
			const showMobileTitle = Modernizr.mq('(max-width: ' + options.mobileTitleUntil + 'px)');
			const key = (options.zipSearchOnly?'zipSearchOnlyTitle':'title') + (showMobileTitle?'Mobile':'');
			
			dfd.resolve(texts[key]);
		});
		
		return dfd.promise();
	}
	selectPointOfService(){
		const overlay = this;
		
		const dfd  = jQuery.Deferred();
		this.deferred = dfd;
		if(overlay.state.opened === true){
			dfd.reject({});
		}else{
			this.state.selected = false;
			
			const resizeFunc = function(){
				setTimeout(function(){
					const cboxOverlay = jQuery('#cboxOverlay');
					if (cboxOverlay.is(':visible')) {
						const cboxContent = jQuery('#cboxContent');
						const cboxPadding = cboxContent.innerWidth() - cboxContent.width();
						
						const overlayContent = cboxContent.find('.select-pos-overlay');

						const contentWidth = overlayContent.width();
						
						jQuery.colorbox.resize({width: contentWidth + cboxPadding});
					}
				}, 50);
			};
			
			this._getTitle().then(function(title){
				jQuery(window).resize(resizeFunc);

				jQuery.colorbox({
					title: title,
					html: overlay.overlayContent.root,
					maxWidth: '95%',
					maxHeight: '500px',
					fixed: true,
					onClosed: function(){
						jQuery(window).off("resize", resizeFunc);
						
						if(!overlay.state.selected){
							dfd.reject({});
						}
					}
				});
				
				if(overlay.options.doInitialSearch){
					if(overlay.options.useInitialLocationSearch){
						overlay._doRemoteLocationSearch();
					}else{
						overlay._doRemoteSavedLocationSearch();
					}
				}
			});
			
			const promise = dfd.promise();
			
			return promise
				.then(function(pos){
					overlay.state.selected = true;
					jQuery.colorbox.close();
					
					return pos;
				})
				.always(function(){
					overlay.state.opened = false;
				});
		}
	}
}

// Private functions

const selectPointOfService = function(productCode, serviceProducts, inStoreOnly, deliveryModeLabel, isAddToCartOverlay, zipSearchOnly, initialTextSearchValue){
	if(typeof serviceProducts === 'undefined'){
		serviceProducts = [];
	}
	
	if(typeof inStoreOnly === 'undefined'){
		inStoreOnly = false;
	}
	
	if(typeof isAddToCartOverlay === 'undefined'){
		isAddToCartOverlay = false;
	}
	
	const product = kps.products[productCode];
	const overlayOpts = {
		product: product,
		serviceProducts: serviceProducts,
		inStoreOnly: inStoreOnly,
		deliveryModeLabel: deliveryModeLabel
	};
	
	if(typeof zipSearchOnly !== 'undefined' && zipSearchOnly){
		overlayOpts.zipSearchOnly = zipSearchOnly;
		overlayOpts.doInitialSearch = false;
		overlayOpts.useInitialLocationSearch = false;
	}
	
	if(isAddToCartOverlay){
		overlayOpts.isAddToCartOverlay = true;
	}
	
	if(initialTextSearchValue !== 'undefined'){
		overlayOpts.initialTextSearchValue = initialTextSearchValue;
	}
	
	const overlay = new SelectPointOfServiceOverlay(overlayOpts);
	return overlay.selectPointOfService();
};

jQuery.widget('ep.checkDeliveryZipCodeOverlay', {
	options: {
		classes: {
			overlay: {
				overlayElm: 'check-delivery-zipcode-overlay',
				success: 'zipcode-result-success',
				fallback: 'zipcode-result-fallback',
				posfallback: 'zipcode-result-posfallback',
				warning: 'zipcode-result-warning',
				initial: 'zipcode-initial',
				hasAlternativePos: 'zipcode-has-alternative-pos'
			},
			fallbackPosElm: 'zipcode-fallback-pos',
			header: {
				header: 'check-delivery-zipcode-header',
				text: 'check-delivery-zipcode-header-text'
			},
			staticContentForm: {
				staticContentForm: 'zipcode-overlay-form',
				staticSearchQuery: 'zipcode-pos-overlay-query',
				staticSubmitButton: 'zipcode-pos-overlay-submit',
			},
			results: {
				resultsWrapperElm: 'zipcode-results-wrapper',
				messageElm: 'zipcode-results-message'
			},
			footer: {
				footerElm: 'zipcode-footer',
				checkElm: 'zipcode-check-btn',
				checkInitialTextElm: 'zipcode-check-initial-text',
				checkTextElm: 'zipcode-check-text',
				continueElm: 'zipcode-continue-btn',
				backElm: 'zipcode-back-btn'
			}
		},
		initialZipCode: undefined,
		deliveryModes: [],
		deliveryModeWeights: {
			spedition: 2,
			servicedelivery: 2,
			twomanhandling: 1
		},
		fallbackDeliveryModes: {
			'servicedelivery': 'twomanhandling',
			 'twomanhandling': 'servicedelivery'
		},
		product: {
			code: undefined,
			name: undefined,
			description: undefined,
			brandName: undefined,
			eek: undefined, // {sign: ..., cssClass: ..., letter: ...}
			offerStr: undefined,
			imageUrl: undefined,
			imageAlt: undefined
		},
		texts: {
			title: __('checkdeliveryzipcodeoverlay.title'),
			header: __('checkdeliveryzipcodeoverlay.header'),
			staticContentForm: {
				placeholder: __('checkdeliveryzipcodeoverlay.staticcontentform.placeholder')
			},
			results: {
				deliverypossible: __('checkdeliveryzipcodeoverlay.results.delivery.possible'),
				deliverynotpossible: __('checkdeliveryzipcodeoverlay.results.delivery.not.possible'),
				fallback: {
					twomanhandling: __('checkdeliveryzipcodeoverlay.results.fallback.twomanhandling'),
					servicedelivery: __('checkdeliveryzipcodeoverlay.results.fallback.servicedelivery'),
				},
				posfallback: {
					twomanhandling: __('checkdeliveryzipcodeoverlay.results.posfallback.twomanhandling'),
					servicedelivery: __('checkdeliveryzipcodeoverlay.results.posfallback.servicedelivery'),
				}
			},
			invalidZipCodeMessage: __('checkdeliveryzipcodeoverlay.invalidZipCodeMessage'),
			footer: {
				checkBtnInitial: __('checkdeliveryzipcodeoverlay.footer.checkBtnInitial'),
				checkBtn: __('checkdeliveryzipcodeoverlay.footer.checkBtn'),
				continueBtn: __('checkdeliveryzipcodeoverlay.footer.continueBtn'),
				backBtn: __('checkdeliveryzipcodeoverlay.footer.backBtn')
			}
		}
	},
	_create: function(){
		this._dfd = jQuery.Deferred();
		
		this._contentElm = this._createContent();
		this._bindListeners();
		this._setUpListeners();
		this._setUpResultListeners();
		this._show();
	},
	_bindListeners: function(){
		const classes = this.options.classes;
		
		const staticSearchQuery = this._contentElm.find('.' + classes.staticContentForm.staticSearchQuery);
		this._on(staticSearchQuery, {
			'change': function(){
				widget._resetSearchState();
			},
			'keydown': function(){
				widget._resetSearchState();
			},
			'keyup': function(){
				widget._resetSearchState();
			}
		});
		
		const form = this._contentElm.find('.' + classes.staticContentForm.staticContentForm);
		this._on(form, {
			submit: '_onFormSubmit'
		});
		
		const footerClasses = classes.footer;
		const checkBtn = this._contentElm.find('.' + footerClasses.checkElm);
		this._on(checkBtn, {
			click: function(){
				form.submit();
			}
		});
		
		const widget = this;
		const continueElm = this._contentElm.find('.' + footerClasses.continueElm);
		this._on(continueElm, {
			click: function(){
				widget._close();
			}
		});
		
		const backElm = this._contentElm.find('.' + footerClasses.backElm);
		this._on(backElm, {
			click: function(){
				widget._close();
			}
		});
	},
	_resetSearchState: function(){
		const classes = this.options.classes;
		const overlayClasses = classes.overlay;
		
		const overlayElm = jQuery('#colorbox').find('.' + overlayClasses.overlayElm);
		overlayElm.removeClass(overlayClasses.initial);
		overlayElm.removeClass(overlayClasses.success);
		overlayElm.removeClass(overlayClasses.warning);
		overlayElm.removeClass(overlayClasses.fallback);
		
		const resultsElm = jQuery('#colorbox').find('.' + classes.results.resultsWrapperElm);
		resultsElm.children().remove();
		
		this._enableForm();
		
		setTimeout(function(){
			jQuery.colorbox.resize();
		});
	},
	getZipCode: function(){
		return this._contentElm.find('.' + this.options.classes.staticContentForm.staticSearchQuery).val();
	},
	_onFormSubmit: function(event){
		const widget = this;
		const options = widget.options;
		
		const allSortedDeliveryModes = this._getFallbackDeliveryModes(options.deliveryModes);
		
		const deliveryModeLabels = allSortedDeliveryModes.join(',');
		let zipCode = this.getZipCode();
		if(typeof zipCode === 'undefined'){
			zipCode = '';
		}else{
			zipCode = zipCode.trim();
		}
		
		const data = {
			deliveryModeLabels: deliveryModeLabels,
			zipCode: zipCode,
			productCode: options.product.code
		};

		this._disableForm();
		this._updateFallbackPosOptions({
			searching: true,
			validationSucceeded: true,
			results: [],
			visible: true
		});
		
		jQuery.colorbox.resize();
		
		const url = '/store-pickup/' + this.options.product.code + '/api/delivery';
		jQuery.ajax({
			 url: url,
			 data: data,
			 method: 'POST',
			cache: false
		 })
		 .then(function(data){
			 const isValidZipCode = data.isValidZipCode;
			 const results = data.results;
			 
			 widget._callResultListeners.apply(widget, [isValidZipCode, results]);
		 }, function(){
			 const results = [];
			 widget._callResultListeners.apply(widget, [false, results]);
		 });
		
		event.preventDefault();
		return false;
	},
	_sortDeliveryModes: function(deliveryModes){
		const deliveryModeWeights = this.options.deliveryModeWeights;
		return _.sortBy(deliveryModes, function(currDeliveryModeLabel){
			let weight = 0;
			
			const configuredWeight = deliveryModeWeights[currDeliveryModeLabel];
			if(typeof configuredWeight !== 'undefined'){
				weight = configuredWeight;
			}
			
			return weight*-1;
		});
	},
	_updateFallbackPosOptions: function(posOptions){
		const fallbackPosElm = this._contentElm.find('.' + this.options.classes.fallbackPosElm);
		fallbackPosElm.posResultsList('option', posOptions);
	},
	_disableForm: function(){
		this._changeFormState(true);
	},
	_enableForm: function(){
		this._changeFormState(false);
	},
	_changeFormState: function(deactivate){
		const staticContentFormClasses = this.options.classes.staticContentForm;
		
		const inputs = this._contentElm.find('.' + staticContentFormClasses.staticContentForm + ' input, button');
		
		inputs.prop('disabled', deactivate);
		if (!deactivate) {
			inputs.removeProp('disabled');
		}
	},
	_callResultListeners: function(success, results){
		const widget = this;
		jQuery.each(this._onResultListeners, function(index, onResultListener){
			onResultListener.apply(widget, [success, results]);
		});
	},
	_setUpResultListeners: function(){
		const widget = this;
		const dfd = this._dfd;
		
		this._success = false;
		this._results = [];
		
		const resultListeners = this._onResultListeners;
		
		resultListeners.push(function(success, results){
			widget._setResults.apply(widget, [success, results]);
		});
		
		resultListeners.push(function(pSuccess, pResults){
			widget._success = pSuccess;
			widget._results = pResults;
		});
		
		resultListeners.push(function(pSuccess, pResults){
			widget._enableForm.apply(widget, []);
		});
		
		resultListeners.push(function(){
			jQuery.colorbox.resize();
		});
	},
	_show: function(){
		const widget = this;
		
		const options = this.options;
		const initialZipCode = options.initialZipCode;
		widget._selectedPos = undefined;
		
		const dfd  = this._dfd;
		
		const resizeFunc = function(){
			setTimeout(function(){
				const cboxOverlay = jQuery('#cboxOverlay');
				if (cboxOverlay.is(':visible')) {
					const cboxContent = jQuery('#cboxContent');
					const cboxPadding = cboxContent.innerWidth() - cboxContent.width();
					
					const overlayContent = cboxContent.find('.' + options.classes.overlay.overlayElm);

					const contentWidth = overlayContent.width();
					
					jQuery.colorbox.resize({width: contentWidth + cboxPadding});
				}
			}, 50);
		};

		jQuery(window).resize(resizeFunc);
		
		const texts = options.texts;
		jQuery.colorbox({
			title: texts.title,
			html: this._contentElm,
			maxWidth: '95%',
			maxHeight: '500px',
			fixed: true,
			onClosed: function(){
				jQuery(window).off("resize", resizeFunc);
				
				widget._close();
			}
		});
	},
	_close: function(){
		const dfd = this._dfd;
		if(this._success){
			dfd.resolve({
				zipCode: this.getZipCode(),
				newPos: this._selectedPos,
			});
		}else{
			dfd.reject({});
		}
	},
	promise: function(){
		return this._dfd.promise();
	},
	_createContent: function(){
		const options = this.options;
		const product = options.product;
		const classes = options.classes;
		
		const contentElm = jQuery('<div/>');
		contentElm.addClass(classes.overlay.overlayElm);
		contentElm.addClass(classes.overlay.initial);
		
		// Header content
		const headerElements = this._createHeaderContent();
		contentElm.append(headerElements.header);
		
		// Form content
		const formContentElmements = this._createFormContent();
		contentElm.append(formContentElmements.staticContentForm);
		
		// Results
		const resultsElm = this._createResultsElm();
		contentElm.append(resultsElm);
		
		// Fallback POS
		const fallbackPosElm = this._createFallbackPosElm();
		contentElm.append(fallbackPosElm);
		
		// Footer
		const footerElm = this._createFooterElm();
		contentElm.append(footerElm);
		
		return contentElm;
	},
	_createHeaderContent: function(){
		const options = this.options;
		
		const headerClasses = options.classes.header;
		
		const textElm = jQuery('<div/>');
		textElm.addClass(headerClasses.text);
		textElm.text(options.texts.header);
		
		const header = jQuery('<div/>');
		header.addClass(headerClasses.header);
		header.append(textElm);
		
		return {
			header: header,
			text: textElm
		}
	},
	_createFormContent: function(){
		const options = this.options;
		const staticContentFormTests = options.texts.staticContentForm;

		const classes = options.classes;
		
		const initialSearchValue = options.initialZipCode;
		const placeholder = staticContentFormTests.placeholder;
		return createStaticContentForm(initialSearchValue, placeholder, classes.staticContentForm);
	},
	_createResultsElm: function(){
		const resultsWrapperElm = jQuery('<div/>');
		resultsWrapperElm.addClass(this.options.classes.results.resultsWrapperElm);
		
		return resultsWrapperElm;
	},
	_createFallbackPosElm: function(){
		const elm = jQuery('<div/>');
		elm.addClass(this.options.classes.fallbackPosElm);
		
		elm.posResultsList({
			searching: false,
			visible: false
		});
		
		const widget = this;
		elm.on('posresultslistselected', function(event, result){
			widget._selectedPos = result;
			widget._close();
		});
		
		return elm;
	},
	_createFooterElm: function(){
		const options = this.options;
		const footerClasses = options.classes.footer;
		const footerTexts = options.texts.footer;
		
		const footerElm = jQuery('<div/>');
		footerElm.addClass(footerClasses.footerElm);
		
		const checkInitialTextElm = jQuery('<span/>');
		checkInitialTextElm.addClass(footerClasses.checkInitialTextElm);
		checkInitialTextElm.text(footerTexts.checkBtnInitial);

		const checkTextElm = jQuery('<span/>');
		checkTextElm.addClass(footerClasses.checkTextElm);
		checkTextElm.text(footerTexts.checkBtn);
		
		const checkElm = jQuery('<button/>');
		checkElm.addClass(footerClasses.checkElm);
		checkElm.append(checkInitialTextElm);
		checkElm.append(checkTextElm);
		
		footerElm.append(checkElm);
		
		const continueElm = jQuery('<button/>');
		continueElm.addClass(footerClasses.continueElm);
		continueElm.text(footerTexts.continueBtn);
		footerElm.append(continueElm);
		
		const backElm = jQuery('<button/>');
		backElm.addClass(footerClasses.backElm);
		backElm.text(footerTexts.backBtn);
		footerElm.append(backElm);
		
		return footerElm;
	},
	_isInDeliveryModes: function(deliveryMode, deliveryModes){
		let deliveryModesToCheck = deliveryModes;
		
		// Denormalize "spedition"
		if(deliveryModesToCheck.length === 1 && deliveryModesToCheck[0] === 'spedition'){
			deliveryModesToCheck = deliveryModesToCheck.concat(speditionDeliveryModes);
		}
		
		// Check
		return deliveryModesToCheck.includes(deliveryMode);
	},
	_getFirstValidDeliveryModeWithType: function(results){
		const options = this.options;
		
		const widget = this;
		
		function isPosFallbackAvailable(deliveryStatusResult){
			const findPosResult = deliveryStatusResult.findPosResult.results;
			return findPosResult.length !== 0;
		}
		
		function getFirstValidDeliveryModeWithType(validDeliveryModes){
			let deliveryModeWithType = undefined;
			
			const fallbackDeliveryModes = widget._getFallbackDeliveryModes(validDeliveryModes);
			
			jQuery.each(results, function(deliveryMode, deliveryStatusResult){
				if(typeof deliveryModeWithType === 'undefined'){
					const isValidDeliveryMode = widget._isInDeliveryModes(deliveryMode, validDeliveryModes);
					const isValidFbDeliveryMode = !isValidDeliveryMode && widget._isInDeliveryModes(deliveryMode, fallbackDeliveryModes);
					if(isValidDeliveryMode || isValidFbDeliveryMode){
						if(deliveryStatusResult.canDeliver){
							deliveryModeWithType = {
								isFallback: isValidFbDeliveryMode,
								isPosFallback: false,
								deliveryMode: deliveryMode
							};
						}else if(isPosFallbackAvailable(deliveryStatusResult)){
							deliveryModeWithType = {
								isFallback: isValidFbDeliveryMode,
								isPosFallback: true,
								deliveryMode: deliveryMode
							};
						}
					}
				}
			});
			
			return deliveryModeWithType;
		}
		
		return getFirstValidDeliveryModeWithType(options.deliveryModes);
	},
	_getFallbackDeliveryModes: function(deliveryModes){
		const fbDeliveryModes = deliveryModes.slice();
		
		const fbDeliveryModeOpts = this.options.fallbackDeliveryModes; 
		
		jQuery.each(deliveryModes, function(index, deliveryMode){
			const fbDeliveryModeOpt = fbDeliveryModeOpts[deliveryMode];
			if(typeof fbDeliveryModeOpt !== 'undefined'){
				fbDeliveryModes.push(fbDeliveryModeOpt);
			}
		});
		
		return this._sortDeliveryModes(fbDeliveryModes);
	},
	_setResults: function(success, results){
		const options = this.options;
		const classes = options.classes;
		const overlayClasses = classes.overlay;
		const resultsClasses = classes.results;
		
		const texts = options.texts;
		const resultTexts = texts.results;
		
		const firstValidDeliveryModeWithType = this._getFirstValidDeliveryModeWithType(results);
		const hasValidDeliveryMode = typeof firstValidDeliveryModeWithType !== 'undefined';
		
		const firstValidDeliveryMode = hasValidDeliveryMode?firstValidDeliveryModeWithType.deliveryMode:undefined;
		const isFallback = hasValidDeliveryMode && firstValidDeliveryModeWithType.isFallback;
		const isPosFallback = hasValidDeliveryMode && firstValidDeliveryModeWithType.isPosFallback;
		
		this._resetSearchState();
		
		const overlayElm = jQuery('#colorbox').find('.' + overlayClasses.overlayElm);
		let overlayClass = undefined;
		
		let messageText = null;
		if(success){
			if(isPosFallback){
				messageText = resultTexts['posfallback'][firstValidDeliveryMode];
				overlayClass = overlayClasses['posfallback'];
			}else if(isFallback){
				messageText = resultTexts['fallback'][firstValidDeliveryMode];
				overlayClass = overlayClasses['fallback'];
			}else if(hasValidDeliveryMode){
				messageText = resultTexts['deliverypossible'];
				overlayClass = overlayClasses['success'];
			}else{
				messageText = resultTexts['deliverynotpossible'];
				overlayClass = overlayClasses['warning'];
			}
		}else{
			messageText = texts.invalidZipCodeMessage;
			overlayClass = overlayClasses['warning'];
		}
		
		overlayElm.addClass(overlayClass);
		
		const messageElm = jQuery('<div/>');
		messageElm.addClass(resultsClasses.messageElm);
		messageElm.text(messageText);
		
		let findPosResults = [];
		if(hasValidDeliveryMode){
			findPosResults = results[firstValidDeliveryMode].findPosResult.results;
		}
		
		const hasAlternativePos = findPosResults.length !== 0;
		
		const hasAlternativePosClass = overlayClasses.hasAlternativePos;
		if(hasAlternativePos){
			overlayElm.addClass(hasAlternativePosClass);
		}else{
			overlayElm.removeClass(hasAlternativePosClass);
		}
		
		const findPosResultsOpts = {
			searching: false,
			results: findPosResults,
			visible: hasAlternativePos
		};
		this._updateFallbackPosOptions(findPosResultsOpts);
		
		const resultsElm = jQuery('#colorbox').find('.' + resultsClasses.resultsWrapperElm);
		resultsElm.append(messageElm);
	},
	_setUpListeners: function(){
		const widget = this;
		
		this._onResultListeners = [];
	}
});

function showCheckDeliveryZipCodeOverlay(productCode, deliveryModes, options){
	const dfd  = jQuery.Deferred();
	
	jQuery(function(){
		const widgetDiv = jQuery('<div style="display; none"/>');
		jQuery('body').append(widgetDiv);
		
		const product = kps.products[productCode];
		options.product = product;
		options.deliveryModes = deliveryModes;
		
		widgetDiv.checkDeliveryZipCodeOverlay(options);
		
		widgetDiv.checkDeliveryZipCodeOverlay('promise').then(function(values){
			dfd.resolve(values);
		}, function(reason){
			dfd.reject(reason);
		}).always(function(){
			widgetDiv.checkDeliveryZipCodeOverlay('destroy');
			widgetDiv.remove();
		});
	});
	
	return dfd.promise();
};

// Exports
export {
	selectPointOfService as selectPointOfService,
	showCheckDeliveryZipCodeOverlay as showCheckDeliveryZipCodeOverlay
};
