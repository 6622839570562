import jQuery from 'jquery';


let initialized = false;

//Private functions
const getLocation = function(){
	const deferred = jQuery.Deferred();
	
	if(navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(function(position) {
			deferred.resolve({
				lat: position.coords.latitude,
				lng: position.coords.longitude
			});
		}, function(error) {
			deferred.reject({
				errorCode: error.code
			});
		});
	}else{
		deferred.reject({
		    errorCode: null
		});
	}
	
	return deferred.promise();
};

//Exports
export {getLocation};