export default function() {
    $(document).ready( function() {
        if (isTouchableScreen()) {
            $("html").addClass("touchevents");
        } else {
            $("html").addClass("no-touchevents");
        }
    })
};

const isTouchableScreen = function(){
    var isTouchable = false;

    if (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
        isTouchable = true;
    }

    return isTouchable;
}

export {isTouchableScreen as isTouchableScreen};
