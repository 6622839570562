const parseUrl = function(url){
	const a =  document.createElement('a');
	a.href = url;
	
	return {
		protocol: a.protocol,
		host: a.hostname,
		port: a.port,
		path: a.pathname.replace(/^([^/])/,'/$1'),
	};
}

export {parseUrl as parseUrl};