import jQuery from 'jquery';
import $script from 'scriptjs';

function loadExtenalScripts(urls){
	const deferred = jQuery.Deferred();
	$script(urls, function(){
		deferred.resolve({
			urls: urls
		});
	}, function(depsNotFound){
		deferred.reject({
			urls: urls,
			depsNotFound: depsNotFound
		});
	});
	
	return deferred.promise();
};

export {loadExtenalScripts as loadExtenalScripts};