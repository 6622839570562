import jQuery from 'jquery';

let currentPointOfService = undefined;

const deferred = jQuery.Deferred();

//Private functions
const setCurrentPointOfService = function(pointOfService){
	currentPointOfService = pointOfService;
}

const getCurrentPointOfService = function(){
	return currentPointOfService;
}

const hasCurrentPointOfService = function(){
	return typeof currentPointOfService !== 'undefined';
}

const isCurrentPointOfService = function(posCode){
	if(hasCurrentPointOfService()){
		const currentPos = getCurrentPointOfService();
		return currentPos.code === posCode;
	}else{
		return false;
	}
}

const getPreselectedPointOfService = function(){
	return kps.preselectedPointOfService;
}

const preselectPointOfService = function(posCode){
	return jQuery.ajax({
		method: 'GET',
		url: kps.preselectUrl,
		data: {
			code: posCode
		},
		cache: false
	});
}

const selectPointOfService = function(posCode){
	return jQuery.ajax({
		method: 'GET',
		url: kps.selectUrl,
		data: {
			code: posCode
		},
		cache: false
	});
}

const getReadyPromise  = function(){
	return deferred.promise();
}

const defaultFunc = function(){
	jQuery(function(){
		if(typeof window.kps !== 'undefined'){
			setCurrentPointOfService(window.kps.pointOfService);
			deferred.resolve({});
		}
	});
}

//Exports
export {
	defaultFunc as default,
	getCurrentPointOfService as getCurrentPointOfService,
	setCurrentPointOfService as setCurrentPointOfService,
	hasCurrentPointOfService as hasCurrentPointOfService,
	isCurrentPointOfService as isCurrentPointOfService,
	preselectPointOfService as preselectPointOfService,
	selectPointOfService as selectPointOfService,
	getPreselectedPointOfService as getPreselectedPointOfService,
	getReadyPromise as getReadyPromise
}