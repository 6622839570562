import jQuery from 'jquery';
import _String from 'underscore.string';

const bodySelectors = {
	pageLabel: ['pageLabel--', 'pageLabel-'],
	template: 'template-pages-templates-',
	pageType: 'pageType-',
	page: 'page-',
	layout: 'layout-',
	language: 'language-'
};

const deferred = jQuery.Deferred();
let extractedSelectors = {};

export default function(){
	jQuery(function(){
		const body = jQuery('body');
		
		if(body.length !== 0){
			const bodyClassNames = body.attr('class').toString().split(' ');
			
			jQuery.each(bodySelectors, function(key, classPrefixes){
				classPrefixes = jQuery.isArray(classPrefixes)?classPrefixes:[classPrefixes];
				
				jQuery.each(classPrefixes, function(index, classPrefix){
					const classesWithPrefix = bodyClassNames.filter(function(className){
						return _String(className).startsWith(classPrefix);
					});
					
					if(classesWithPrefix.length !== 0){
						const matchedClass = classesWithPrefix[0];
						
						const classValue = matchedClass.substring(classPrefix.length);
						
						extractedSelectors[key] = classValue;
						
						return false; //BREAK
					}
				});
			});
		}
		
		deferred.resolve();
	});
}

function matchesPageSelector(pageSelector){
	let matches = true;
	
	jQuery.each(pageSelector, function(key, classPrefix){
		if(extractedSelectors[key] !== classPrefix){
			matches = false;
			return false;
		}
	})
	
	return matches;
}

export function registerPageCb(pageSelector, cb){
	deferred.then(function(){
		if(matchesPageSelector(pageSelector)){
			cb();
		}
	});
}