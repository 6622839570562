import {registerPageCb} from '../../services/pagesService';
import {trackAddressSelectStep, trackAddressRegisterLoginGastStep, trackAddressDeliveryTypeStepNumberStep} from '../ecommercefunnel';
import jQuery from 'jquery';

export default function(){
	registerPageCb({
		pageLabel: 'checkout-address-select'
	}, function(){
		trackAddressSelectStep();
		
		const components = jQuery('.checkout-address-and-register-component');
		components.each(function(){
			const component = jQuery(this);
			
			const form = component.find('#epCheckoutAddressStepForm');
			
			form.on('submit', function(event){
				if(!form.hasClass('submited')){
					form.addClass('submited');
					
					event.preventDefault();
					
					const formData = new FormData(form[0])
					const getDeliveryType = function(){
						let deliveryType = 'Delivery';
						
						const serviceDeliveryDescription = component.find('.service-delivery-description');
						
						if(serviceDeliveryDescription.length !== 0){
							deliveryType = 'ServiceDelivery';
						}else{
							const invoiceAddressUsage = formData.get('invoiceAddressUsage');
							if(invoiceAddressUsage == 'OTHER'){
								const useOtherAddressOption = formData.get('useOtherAddressOption');
							
								if(useOtherAddressOption == 'DELIVERY_ADDRESS'){
									//Nothing to do
								}else if(useOtherAddressOption == 'PACKSTATION'){
									deliveryType = 'Delivery_Packstation';
								}else if(useOtherAddressOption == 'MY_STORE'){
									deliveryType = 'Pickup';
								}else{
									deliveryType = useOtherAddressOption;
									console.warn("Unknown option: " + useOtherAddressOption);
								}
							}
						}
						
						return deliveryType;
					}
					
					const getCustomerType = function(){
						let customerType = 'Guest';
						
						const customerTypeData = component.data('customer-type');
						if(formData.get('pwd') != ''){
							customerType = 'User';
						}else{
							if(customerTypeData == 'ANONYMOUS' || customerTypeData == 'GUEST'){
								//Nothing to do
							}else{
								customerType = 'User';
							}
						}
						
						return customerType;
					}
					
					const deliveryType = getDeliveryType();
					const customerType = getCustomerType();
					
					jQuery.when(
						trackAddressRegisterLoginGastStep(customerType),
						trackAddressDeliveryTypeStepNumberStep(deliveryType)
					).always(function(){
						form.submit();
					});
				}
			});
		});
	});
}