import jQuery from 'jquery';
import {getProductInfos} from '../../services/cartService';
import {isGoogleTagManagerEnabled, queueDataLayerEvents} from './../tracking';
import {difference, intersection} from 'underscore';

function createCartModificationsContext(){
	const productInfos = getProductInfos();
	
	return jQuery.extend(true, {}, productInfos);
}

function getProductInfosObj(productInfos){
	const productInfosObj = {};
	
	jQuery.each(productInfos, function(index, productInfo){
		productInfosObj[productInfo.code] = productInfo;
	});
	
	return productInfosObj;
}

function calculateModifications(cartModificationsContext, cartModificationsContextAfter){
	const addedModifications = [];
	const removeModifications = [];
	
	const productInfosObjBefore = getProductInfosObj(cartModificationsContext);
	const productInfosObjAfter = getProductInfosObj(cartModificationsContextAfter);
	
	const codesBefore = Object.keys(productInfosObjBefore);
	const codesAfter = Object.keys(productInfosObjAfter);
	
	const added = difference(codesAfter, codesBefore);
	jQuery.each(added, function(index, productCode){
		const productInfoAfter = productInfosObjAfter[productCode];
		
		const modification = jQuery.extend(true, {}, productInfoAfter);
		delete modification.serviceProducts;
		
		addedModifications.push(modification);
		
		//Add service products
		if(typeof productInfoAfter.serviceProducts !== 'undefined'){
			jQuery.each(productInfoAfter.serviceProducts, function(key, serviceProductInfo){
				const spModification = jQuery.extend(true, {}, serviceProductInfo);
				spModification.code = key;
				
				addedModifications.push(spModification);
			});
		}
	});
	
	const removed = difference(codesBefore, codesAfter);
	jQuery.each(removed, function(index, productCode){
		const productInfoBefore = productInfosObjBefore[productCode];
		
		const modification = jQuery.extend(true, {}, productInfoBefore);
		delete modification.serviceProducts;
		
		removeModifications.push(productInfoBefore);
		
		//Remove service products
		if(typeof productInfoBefore.serviceProducts !== 'undefined'){
			jQuery.each(productInfoBefore.serviceProducts, function(key, serviceProductInfo){
				const spModification = jQuery.extend(true, {}, serviceProductInfo);
				spModification.code = key;
				
				removeModifications.push(spModification);
			});
		}
	});
	
	const mantained = intersection(codesBefore, codesAfter);
	jQuery.each(mantained, function(index, productCode){
		const productInfoBefore = productInfosObjBefore[productCode];
		const productInfoAfter = productInfosObjAfter[productCode];
		
		const quantityDifference = productInfoBefore.quantity - productInfoAfter.quantity;
		if(quantityDifference !== 0){
			const modification = jQuery.extend(true, {}, productInfoAfter);
			modification.quantity = Math.abs(quantityDifference);
			delete modification.serviceProducts;
			
			const modificationsArray = (quantityDifference > 0)?removeModifications:addedModifications;
			modificationsArray.push(modification);
			
		}
		
		//Add/remove service products
		const serviceProductsBefore = (typeof productInfoBefore.serviceProducts === 'undefined')?[]:productInfoBefore.serviceProducts;
		const serviceProductsAfter = (typeof productInfoAfter.serviceProducts === 'undefined')?[]:productInfoAfter.serviceProducts;
		
		const serviceProductCodesBefore = Object.keys(serviceProductsBefore);
		const serviceProductCodesAfter = Object.keys(serviceProductsAfter);
		
		const spAdded = difference(serviceProductCodesAfter, serviceProductCodesBefore);
		jQuery.each(spAdded, function(index, serviceProductCode){
			const serviceProductInfoAfter = serviceProductsAfter[serviceProductCode];
			
			const modification = jQuery.extend(true, {}, serviceProductInfoAfter);
			modification.code = serviceProductCode;
			
			addedModifications.push(modification);
		});
		
		const spRemoved = difference(serviceProductCodesBefore, serviceProductCodesAfter);
		jQuery.each(spRemoved, function(index, serviceProductCode){
			const serviceProductInfoBefore = serviceProductsBefore[serviceProductCode];
			
			const modification = jQuery.extend(true, {}, serviceProductInfoBefore);
			modification.code = serviceProductCode;
			
			removeModifications.push(modification);
		});
		
		const spMantained = intersection(serviceProductCodesBefore, serviceProductCodesAfter);
		jQuery.each(spMantained, function(index, serviceProductCode){
			const serviceProductInfoBefore = serviceProductsBefore[serviceProductCode];
			const serviceProductInfoAfter = serviceProductsAfter[serviceProductCode];
			
			const spQuantityDifference = serviceProductInfoBefore.quantity - serviceProductInfoAfter.quantity;
			if(spQuantityDifference !== 0){
				const modification = jQuery.extend(true, {}, serviceProductInfoAfter);
				modification.quantity = Math.abs(spQuantityDifference);
				modification.code = serviceProductCode;
				
				const modificationsArray = (quantityDifference > 0)?removeModifications:addedModifications;
				modificationsArray.push(modification);
			}
		});
	});

	
	return {
		added: addedModifications,
		removed: removeModifications
	};
}

function mapModificationToProduct(modification){
	return {
		'name': modification.etrackerName,
		'id': modification.code,
		'price': modification.basePrice.toString(),
		'brand': modification.brand,
		'category': modification.etCategory,
		'quantity': modification.quantity
	}
}

function generateCartAddModificationsAddEvent(addedModifications){
	const products = addedModifications.map(mapModificationToProduct);
	
	return {
		'event': 'addToCart',
		'ecommerce': {
			'add': {
				products: products
			}
		}
	};
}

function generateCartRemoveModificationsEvent(removeModifications){
	const products = removeModifications.map(mapModificationToProduct);
	
	return {
		'event': 'removeFromCart',
		'ecommerce': {
			'remove': {
				products: products
			}
		}
	};
}

function generateCartChangesEvents(modifications){
	const events = [];
	
	if(modifications.removed.length !== 0){
		const removeEvent = generateCartRemoveModificationsEvent(modifications.removed);
		events.push(removeEvent);
	}
	
	if(modifications.added.length !== 0){
		const addEvent = generateCartAddModificationsAddEvent(modifications.added);
		events.push(addEvent);
	}
	
	return events;
}

function doTrackCartChanges(oldCartModificationsContext, afterCartContextFactoryFn){
	const dfd  = jQuery.Deferred();
	if(isGoogleTagManagerEnabled()){
		const cartModificationsContextAfter = (typeof afterCartContextFactoryFn === 'undefined')?createCartModificationsContext():afterCartContextFactoryFn(oldCartModificationsContext);
		const modifications = calculateModifications(oldCartModificationsContext, cartModificationsContextAfter);
		const events = generateCartChangesEvents(modifications);
		if(events.length === 0){
			dfd.resolve();
		}else{
			return queueDataLayerEvents(events, true);
		}
	}else{
		return dfd.reject();
	}
	
	return dfd.promise();
}

export function trackCartChanges(cartMutatorFn, afterCartContextFactoryFn){
	const cartModificationsContext = createCartModificationsContext();
	
	const mutatorResult = cartMutatorFn();
	
	const promise = (typeof mutatorResult !== 'undefined' && typeof mutatorResult.always !== 'undefined')?mutatorResult:(jQuery.Deferred().resolve().promise());
	promise.always(function(){
		return doTrackCartChanges(cartModificationsContext, afterCartContextFactoryFn);
	});
	
	return promise;
}