import jQuery from 'jquery';
import {
	hasCurrentPointOfService,
	getCurrentPointOfService,
	setCurrentPointOfService
	} from './pointOfServicesService';
import {selectPointOfService} from './productService';
import {difference} from 'underscore';
import {size as uSize} from 'underscore';
import {trackCartChanges} from '../tracking/services/cartServiceTracking';

let cartData = undefined;
let urls = undefined;


//Private methods
const getCartInfo = function(){
	const cart = cartData;
	if(typeof cart === 'undefined'){
		console.warn('The cart object is empty!');
	}
	
	return cart;
}

const getUrl = function(urlKey){
	const url = urls[urlKey];
	if(typeof url === 'undefined'){
		console.warn('Url for key ' + urlKey + ' not found.');
		return undefined;
	}
	
	return url;
}

const doRemoteCall = function(url, data){
	return jQuery.ajax({
		url : url,
		type : "GET",
		data: data,
		cache: false
	});
}

const remoteCalls = {
	addToCartDirectWithServices: function(productCode, serviceProductCodes, deliveryModeLabel, posCode){
		const url = getUrl('addToCartDirectWithServices');
		
		const data = {
			productCode: productCode,
			serviceProductCodes: serviceProductCodes.join()
		};
		
		if(typeof deliveryModeLabel !== 'undefined'){
			data.deliveryModeLabel = deliveryModeLabel;
		}
		
		if(typeof posCode !== 'undefined'){
			data.posCode = posCode;
		}
		
		return doRemoteCall(url, data);
	},
	addServiceProducts: function(productCode, serviceProductCodes){
		const url = getUrl('addServiceProducts');
		
		const data = {
			productCode: productCode,
			serviceProductCodes: serviceProductCodes.join()
		};
		
		return doRemoteCall(url, data);
	},
	removeServiceProduct: function(productCode, serviceProductCode, deliveryModeLabel){
		const url = getUrl('removeServiceProduct');
		
		const data = {
			productCode: productCode,
			serviceProductCode: serviceProductCode
		};
		
		if(typeof deliveryModeLabel !== 'undefined'){
			data.deliveryModeLabel = deliveryModeLabel;
		}
		
		return doRemoteCall(url, data);
	},
	addToCartDirect: function(productCode, deliveryModeLabel, posCode){
		const url = getUrl('addToCartDirect');
		
		const data = {
			productCodePost: productCode
		};
		
		if(typeof deliveryModeLabel !== 'undefined'){
			data.deliveryModeLabel = deliveryModeLabel;
		}
		
		if(typeof posCode !== 'undefined'){
			data.posCode = posCode;
		}
		
		return doRemoteCall(url, data);
	},
	replaceServiceProduct: function(productCode, serviceProductCode){
		const url = getUrl('replaceServiceProduct');
		
		const data = {
			productCode: productCode,
			serviceProductCode: serviceProductCode
		};
		
		return doRemoteCall(url, data);
	}
}

const getRemoteCall = function(remoteCallKey){
	return function(){
		return remoteCalls[remoteCallKey]
				.apply(window, arguments)
				.then(
						function(cartResult, statusText, xhr, formElement){
							jQuery('.pdp-overlay').hide();
							let newCartData = cartResult.kpsCartData;
							
							cartData = newCartData;
							
							const dfd  = jQuery.Deferred();
							if(typeof cartResult.errorMessage !== 'undefined'){
								dfd.reject({
									error: cartResult.errorMessage,
									errorKey: cartResult.errorKey,
									cartData: cartData
								});
							}else{
								dfd.resolve({
									message: cartResult.successMsg,
									messageKey: cartResult.successKey,
									cartData: cartData
								});
							}
							
							return dfd.promise();
						}
					)
				.then(function(data){
					jQuery('body').trigger('cart:added', {
						cartData: data.cartData
					});
					
					if(typeof data.message !== 'undefined'){
						const message = data.message.trim();
						
						if(message.length !== 0){
							jQuery('body').trigger('message', {
								text: data.message,
								type: 'success',
								hint: ['cart', remoteCallKey]
							});
						}
					}

					const dfd  = jQuery.Deferred();
					dfd.resolve(data);
					
					return dfd.promise();
				}, function(data){
					jQuery('body').trigger('cart:error', {
						error: data.error,
						cartData: data.cartData
					});
					
					if(typeof data.error !== 'undefined'){
						const error = data.error.trim();
						
						if(error.length !== 0){
							jQuery('body').trigger('message', {
								text: data.error,
								type: 'danger',
								hint: ['cart', remoteCallKey]
							});
						}
					}
					
					const dfd  = jQuery.Deferred();
					dfd.reject(data);
					
					return dfd.promise();
				})
			;
	};
}

const getProductInfo = function(productCode){
	var cartInfo = getCartInfo();
	return cartInfo.items[productCode];
}

const getProductInfos = function(){
	const productInfos = [];
	
	const cartInfo = getCartInfo();
	jQuery.each(cartInfo.items, function(key, value){
		const productInfo = jQuery.extend({}, value);
		productInfo.code = key;
		
		productInfos.push(productInfo);
	});
	
	return productInfos;
}

const cartIsEmpty = function(){
	const cartInfo = getCartInfo();
	
	const size = uSize(cartInfo.items);
	
	return size === 0;
}

const cartContainsProduct = function(productCode){
	var productInfo = getProductInfo(productCode);
	return (typeof productInfo !== 'undefined');
}

const getServiceProductInfo = function(productCode, serviceProductCode){
	let serviceProductInfo = undefined;
	
	const productInfo = getProductInfo(productCode);
	if(typeof productInfo !== 'undefined'){
		serviceProductInfo = productInfo.serviceProducts[serviceProductCode];
	}
	
	return serviceProductInfo;
}

const productContainsServiceProduct = function(productCode, serviceProductCode){
	const serviceProductInfo = getServiceProductInfo(productCode, serviceProductCode);
	return (typeof serviceProductInfo !== 'undefined');
}

const getServiceProductCodesForProduct = function(productCode, filter){
	const serviceProductCodes = [];
	
	if(cartContainsProduct(productCode)){
		const productInfo = getProductInfo(productCode);
		jQuery.each(productInfo.serviceProducts, function(serviceProductCode, serviceProductInfo){
			if(typeof filter === 'undefined' || filter(serviceProductCode, serviceProductInfo)){
				serviceProductCodes.push(serviceProductCode);
			}
		});
	}
	
	return serviceProductCodes;
};

const sortedArrayEquals = function(array1, array2){
	let areEqual = true;
	
	if(array1.length === array2.length){
		for(let i = 0; i < array1.length; i++){
			const array1Item = array1[i];
			const array2Item = array2[i];
			
			if(array1Item !== array2Item){
				areEqual = false;
				break;
			}
		}
	}else{
		areEqual = false;
	}
	
	return areEqual;
}

const _diff = function(original, newArray){
	return {
		added: difference(newArray, original),
		removed: difference(original, newArray)
	};
}

const addToCart = function(productCode, serviceProductCodes, deliveryModeLabel, posCode){
	return trackCartChanges(function(){
		const cartInfo = getCartInfo();
		
		if(typeof serviceProductCodes === 'undefined'){
			serviceProductCodes = [];
		}
		
		const cartServiceProductCodes = getServiceProductCodesForProduct(productCode);
		const diff = _diff(cartServiceProductCodes, serviceProductCodes);
		
		let promise = undefined;
		//Remove service products first
		jQuery.each(diff.removed, function(index, serviceProductCode){
			if(typeof promise === 'undefined'){
				promise = getRemoteCall('removeServiceProduct')(productCode, serviceProductCode);
			}else{
				promise.then(function(){
					return getRemoteCall('removeServiceProduct')(productCode, serviceProductCode);
				});
			}
		});
		
		//Add products
		if(diff.added.length === 0){
			if(typeof promise === 'undefined'){
				promise = getRemoteCall('addToCartDirect')(productCode, deliveryModeLabel, posCode);
			}else{
				promise.then(function(){
					return getRemoteCall('addToCartDirect')(productCode, deliveryModeLabel, posCode);
				});
			}
		}else{
			if(typeof promise === 'undefined'){
				promise = getRemoteCall('addToCartDirectWithServices')(productCode, serviceProductCodes, deliveryModeLabel, posCode);
			}else{
				promise.then(function(){
					return getRemoteCall('addToCartDirectWithServices')(productCode, serviceProductCodes, deliveryModeLabel, posCode);
				});
			}
		}
		return promise;
	});
}

let initialized = false;
const defaultFunc = function(){
	if(typeof window.kps !== 'undefined' && typeof window.kps.cart !== 'undefined' && !initialized){
		cartData = window.kps.cart.cart;
		urls = window.kps.cart.urls;
		
		initialized = true;
	}
};

const replaceServiceProduct = function(productCode, serviceProductCode){
	return trackCartChanges(function(){
		return getRemoteCall('replaceServiceProduct')(productCode, serviceProductCode);
	});
};

const removeServiceProduct = function(productCode, serviceProductCode, deliveryModeLabel){
	return trackCartChanges(function(){
		return getRemoteCall('removeServiceProduct')(productCode, serviceProductCode, deliveryModeLabel);
	});
};

const addServiceProducts = function(productCode, serviceProductCodes){
	return trackCartChanges(function(){
		return getRemoteCall('addServiceProducts')(productCode, serviceProductCodes);
	});
};

const getCurrentQuantity =  function(productCode){
	let quantity = 0;
	if(cartContainsProduct(productCode)){
		const productInfo = getProductInfo(productCode);
		quantity = productInfo.quantity;
	}
	
	return quantity;
};

const getDeliveryModeLabel = function(){
	const cartInfo = getCartInfo();

	return cartInfo.deliveryModeLabel;
};
	
//Public methods
export {
	defaultFunc as default,
	addToCart as addToCart,
	cartIsEmpty as cartIsEmpty,
	replaceServiceProduct,
	removeServiceProduct,
	addServiceProducts,
	getProductInfo,
	cartContainsProduct,
	getCurrentQuantity,
	getServiceProductCodesForProduct,
	getServiceProductInfo,
	productContainsServiceProduct,
	getProductInfos,
	getDeliveryModeLabel
};
