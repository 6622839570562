import tracking from './tracking';
import services from './services/_services';
import pages from './pages/_pages';
import trackingService from './services/tracking';


let initialized = false;
export default function(){
	if(!initialized){
		initialized = true;
		
		services();
		tracking();
		pages();
		trackingService();
	}
}