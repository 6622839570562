import jQuery from 'jquery';
import {cartIsEmpty} from '../../services/cartService';
import {registerPageCb} from '../../services/pagesService';
import {trackPaymentStep} from '../ecommercefunnel';
import {epresponsivecheckoutpaymentcomponent} from '../../components/epresponsivecheckoutpaymentcomponent';

export default function(){
	const cartHasProducts = !cartIsEmpty();
	if(cartHasProducts){
		registerPageCb({
			pageLabel: 'checkout-payment-method-add'
		}, function(){
			const component = jQuery('.ep-responsive-checkout-payment-component');
			
			component.on('checkoutpaymentcomponentchange', function(event, data){
				trackPaymentStep(data.value);
			});
		});
	}
}