import jQuery from 'jquery';
import {cartIsEmpty, default as intializeCartService} from '../../services/cartService';
import {registerPageCb} from '../../services/pagesService';
import {trackCartStep} from '../ecommercefunnel';

export default function(){
	intializeCartService();
	const cartHasProducts = !cartIsEmpty();
	if(cartHasProducts){
		registerPageCb({
			pageLabel: 'cart'
		}, function(){
			trackCartStep();
		});
	}
}