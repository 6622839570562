import {isGoogleTagManagerEnabled, queueDataLayerEvents} from './tracking';
import jQuery from 'jquery';
import {getProductInfos} from '../services/cartService';

const cartStepNumber = 1;
const addressSelectStepNumber = 2;
const addressRegisterLoginGastStepNumber = 3;
const addressDeliveryTypeStepNumber = 4;
const paymentStepNumber = 5;
const summaryStepNumber = 6;

function getProducts(productInfos){
	let products = [];
	
	jQuery.each(productInfos, function(key, productInfo){
		const isServiceProduct = typeof productInfo.type !== 'undefined';
		
		const product = {
			id: ((isServiceProduct)?key:productInfo.code),
			name: productInfo.etrackerName,
			quantity: productInfo.quantity,
			price: productInfo.basePrice.toString(),
			brand: productInfo.brand,
			category: productInfo.etCategory,
		};
		
		products.push(product);
		
		if(typeof productInfo.serviceProducts !== 'undefined'){
			const serviceProductInfos = productInfo.serviceProducts;
			delete productInfo.serviceProducts;
			
			const serviceProducts = getProducts(serviceProductInfos);
			products = products.concat(serviceProducts);
		}
	});
	
	return products;
}

function getProductsFromCart(){	
	const productInfos = getProductInfos();

	return getProducts(productInfos);
}

function trackEcommerceStep(stepNumber, overridedProducts, option, checkoutType){
	const deferred = jQuery.Deferred();
	if(isGoogleTagManagerEnabled()){
		const products = (typeof overridedProducts === 'undefined')?getProductsFromCart():overridedProducts;
		
		const event = {
			'event': 'checkout',
			'ecommerce': {
				'checkout': {
					'actionField': {
						'step': stepNumber,
						option: option
					},
					'products': products
				}
			},
			'eventCallback': function() {
				deferred.resolve();
			}
		};
		
		if(typeof checkoutType !== 'undefined'){
			event.checkoutType = checkoutType;
		}
		
		queueDataLayerEvents(event, true);
	}else{
		deferred.reject();
	}
}

function getCheckoutType(isExpressReservationFlag){
	const isExpressReservation = typeof isExpressReservationFlag != 'undefined' && isExpressReservationFlag;
	
	return isExpressReservation?'ExpressReservation':'MultiStep';
}

export {getCheckoutType as getCheckoutType};

export function trackCartStep(){
	const checkoutType = getCheckoutType(false);
	return trackEcommerceStep(cartStepNumber, undefined, undefined, checkoutType);
}

export function trackAddressSelectStep(){
	const checkoutType = getCheckoutType(false);
	
	return trackEcommerceStep(addressSelectStepNumber, undefined, undefined, checkoutType);
}

export function trackAddressRegisterLoginGastStep(option, isExpressReservationFlag){
	const checkoutType = getCheckoutType(isExpressReservationFlag);
	
	return trackEcommerceStep(addressRegisterLoginGastStepNumber, undefined, option, checkoutType);
}

export function trackAddressDeliveryTypeStepNumberStep(option, isExpressReservationFlag){
	const checkoutType = getCheckoutType(isExpressReservationFlag);
	
	return trackEcommerceStep(addressDeliveryTypeStepNumber, undefined, option, checkoutType);
}

export function trackPaymentStep(option){
	const checkoutType = getCheckoutType(false);
	
	return trackEcommerceStep(paymentStepNumber, undefined, option, checkoutType);
}

export function trackSummaryStep(isExpressReservationFlag){
	const checkoutType = getCheckoutType(isExpressReservationFlag);
	
	return trackEcommerceStep(summaryStepNumber, undefined, undefined, checkoutType);
}