import {isGoogleTagManagerEnabled, queueDataLayerEvents} from '../tracking';
import {getCheckoutType} from '../ecommercefunnel';
import {registerPageCb} from '../../services/pagesService';
import jQuery from 'jquery';
import URI from 'urijs';

function extractCartInformation(cartComponent){
	const products = [];
	
	cartComponent.find('.cart-table-entry').each(function(){
		const cartEntry = jQuery(this);
		
		const dataObj = cartEntry.data();
		
		const hasIdealoChannel = typeof dataObj['idealoChannel'] !== 'undefined' && dataObj['idealoChannel'];
		const hasChannelSight = typeof dataObj['channelsight'] !== 'undefined' && dataObj['channelsight'];
		const hasHatch = typeof dataObj['hatch'] !== 'undefined' && dataObj['hatch'];

		let imageUrl = cartEntry.find('.primary-image').attr('src');
		
		let url = dataObj['url'];
		if(typeof url !== 'undefined'){
			url = new URI().pathname(url).unicode();
		}
		
		const productData = {	
			'name': dataObj['etName'],
			'id': dataObj['etCode'],
			'price': cartEntry.attr('data-et-price'),
			'netprice': cartEntry.attr('data-et-netprice'),
			'category': dataObj['etCategory'],
			'quantity': dataObj['quantity'],
			'ean': dataObj['ean'],
			'idealoChannel': hasIdealoChannel,
			'channelSight': hasChannelSight,
			'hatch': hasHatch,
			'image': imageUrl,
			'url': url,
			'type': dataObj['type']
		};
		
		const brand = dataObj['brandname'];
		if(typeof brand !== 'undefined'){
			productData.brand = brand;
		}
		
		products.push(productData);
	});
	
	const actionField = {
		'id': cartComponent.data('cart-code'),
		'orderId': cartComponent.data('order-code'),
		'revenue': cartComponent.attr('data-total-price'), 
		'tax': cartComponent.attr('data-total-tax'),
		'shipping': cartComponent.attr('data-delivery-costs'),
		'isIdealoOrder': cartComponent.data('is-idealo-order'),
		'paymentMode': cartComponent.data('payment-mode'),
		'customerEmail': cartComponent.data('customer-email'),
		'deliveryMode': cartComponent.data('delivery-mode'),
		'isChannelSight': cartComponent.data('is-channelsight'),
		'isHatch': cartComponent.data('is-hatch')
	};
	
	return {
		products: products,
		actionField: actionField
	};
}

function trackOrderConfirmation(cartInformation){
	const isExpressReservation = cartInformation.actionField.deliveryMode == 'reserve';
	const checkoutType = getCheckoutType(isExpressReservation);
	
	queueDataLayerEvents({
		'ecommerce': {
			'purchase': cartInformation
		},
		'event': 'orderConfirmation',
		'checkoutType': checkoutType
	});
}

export default function(){
	if(isGoogleTagManagerEnabled()){
		registerPageCb({
			page: 'epresponsive-orderConfirmationPage'
		}, function(){
			const cartComponent = jQuery('.cart-component');
			if(typeof orderSentEvent !== 'undefined' && cartComponent.length !== 0){
				const cartInformation = extractCartInformation(cartComponent);

				//if (cartInformation.products)
				//OrderConfirmation event
				trackOrderConfirmation(cartInformation);
			}
		});
	}
}
